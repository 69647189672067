export const regions = [
  {
    name: 'Australia',
    code: 'au',
  },
  {
    name: 'Europe',
    code: 'eu',
  },
  {
    name: 'US',
    code: 'us',
  },
];

export const regionsWithAll = [
  {
    name: 'All',
    code: '',
  },
  ...regions,
];
