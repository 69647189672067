import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import AdornedButton from '../../Button/AdornedButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { useTranslation } from 'react-i18next';
import Autocomplete from '@mui/material/Autocomplete';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import * as API from '../../../constants/api';
import { showToast } from '../../../store/actions/toastAction';
import { getErrorMsg } from '../../../utils/common';

const PREFIX = 'SendNotificationDialog';

const classes = {
  select: `${PREFIX}-select`
};

const StyledDialog = styled(Dialog)((
  {
    theme
  }
) => ({
  [`& .${classes.select}`]: {}
}));

const SEND_NOTIFICATION_STATE = {
  device: '',
  title: '',
  html: '',
};

const SendNotificationDialog = ({ show, toggler, devices, pulseOnlineDevices }) => {
  const { t } = useTranslation();


  const dispatch = useDispatch();
  const [selectedDevice, setSelectedDevice] = useState();
  const [loading, setLoading] = useState(false);

  const [notification, setNotification] = useState(SEND_NOTIFICATION_STATE);

  const onClose = () => {
    toggler(false);
    setNotification(SEND_NOTIFICATION_STATE);
  };

  const onChange = event => {
    event.persist();
    setNotification(prevMessage => ({
      ...prevMessage,
      [event.target.name]: event.target.value,
    }));
  };
  const sendPushNotification = async event => {
    setLoading(true);

    try {
      await axios.post(API.SEND_NOTIFICATION_TO_DEVICE, {
        environment: process.env.REACT_APP_ENVIRONMENT,
        deviceId: selectedDevice?.id,
        title: notification.title,
        message: notification.html,
      });
      dispatch(showToast(t('notification_sent_success')));
    } catch (e) {
      dispatch(showToast(getErrorMsg(e), 'error'));
    } finally {
      setLoading(false);
    }
    event.preventDefault();
  };

  const isInvalid = !notification.title || !notification.html || !selectedDevice;

  return (
    <StyledDialog open={show || false} onClose={onClose}>
      <DialogTitle>{t('send_push_notification')}</DialogTitle>
      <DialogContent>
        <Autocomplete
          id="device-standard"
          options={devices.filter(device => pulseOnlineDevices?.includes(device.id))}
          getOptionLabel={option => option.title ?? ''}
          onChange={(event, values) => setSelectedDevice(values)}
          value={selectedDevice}
          className={classes.select}
          renderInput={params => (
            <TextField {...params} variant="standard" label={t('device')} placeholder={t('device')} name="device" />
          )}
          renderOption={(props, option) => (
            <li {...props} key={option.id}>
              {option.title}
            </li>
          )}
        />
        <TextField
          margin="dense"
          id="title"
          label={t('title')}
          type="text"
          name="title"
          fullWidth
          value={notification.title}
          onChange={onChange}
          autoFocus
          disabled={loading}
        />
        <TextField
          margin="dense"
          id="html"
          label={t('html')}
          type="text"
          name="html"
          fullWidth
          multiline
          onChange={onChange}
          minRows={4}
          value={notification.html}
          autoFocus
          disabled={loading}
        />
      </DialogContent>
      <DialogActions>
        <AdornedButton variant="contained" onClick={onClose} disabled={loading}>
          {t('close')}
        </AdornedButton>
        <AdornedButton
          variant="contained"
          color="primary"
          disabled={isInvalid || loading}
          onClick={sendPushNotification}
          loading={loading}
        >
          {t('send')}
        </AdornedButton>
      </DialogActions>
    </StyledDialog>
  );
};
export default SendNotificationDialog;
