import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Container from '@mui/material/Container';
import CircularProgress from '@mui/material/CircularProgress';
import { withFirebase } from '../Firebase';
import TableIcons from '../../constants/tableIcons';
import { useTranslation } from 'react-i18next';
import MaterialTable from '../MaterialTable/MaterialTable';
import 'jspdf-autotable';
import Button from '@mui/material/Button';
import DownloadIcon from '@mui/icons-material/CloudDownload';
import { useDispatch } from 'react-redux';
import { showToast } from '../../store/actions/toastAction';
import { getDocs } from 'firebase/firestore';

const PREFIX = 'index';

const classes = {
  table: `${PREFIX}-table`,
  selectContainer: `${PREFIX}-selectContainer`,
  selectLabel: `${PREFIX}-selectLabel`,
  datePicker: `${PREFIX}-datePicker`
};

const StyledContainer = styled(Container)((
  {
    theme
  }
) => ({
  [`& .${classes.table}`]: {
    margin: theme.spacing(2, 0),
  },

  [`& .${classes.selectContainer}`]: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '15px',
  },

  [`& .${classes.selectLabel}`]: {
    marginRight: '15px',
  },

  [`& .${classes.datePicker}`]: {
    flex: 1,
  }
}));

const BillingReportTable = ({ data, columns }) => {
  const { t } = useTranslation();
  return <MaterialTable icons={TableIcons} title={t('monthly_billing_reports')} columns={columns} data={data} />;
};

const BillingReportPage = props => {

  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [reports, setReports] = useState([]);
  const dispatch = useDispatch();

  const downloadPdf = site => {
    window.open(site.url, '_blank');
  };

  const columns = [
    {
      title: t('name'),
      field: 'fileName',
    },
    {
      title: t('download'),
      render: rowData => (
        <Button
          variant="contained"
          startIcon={<DownloadIcon />}
          onClick={() => downloadPdf(rowData)}>
          {t('pdf_report')}
        </Button>
      ),
    },
  ];

  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);
        const reportSnapshot = await getDocs(props.firebase.billingReports());
        const reports = reportSnapshot.docs.map(doc => doc.data());

        setReports(reports);
        setLoading(false);
      } catch (error) {
        dispatch(showToast(`${error}`, 'error'));
        setLoading(false);
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledContainer maxWidth="xl">
      {!loading ? (
        <div>
          <div className={classes.table}>
            <BillingReportTable columns={columns} data={reports} />
          </div>
        </div>
      ) : (
        <div style={{ textAlign: 'center' }}>
          <CircularProgress />
        </div>
      )}
    </StyledContainer>
  );
};

export default withFirebase(BillingReportPage);
