import React from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import * as ROUTES from '../../constants/routes';
import { checkIfPermissionIsGranted } from '../../utils/common';

const ProtectedRoute = props => {
  const { redirectPath, component, user, loggedIn, ...routeProps } = props;
  const Component = component;
  const location = useLocation();
  const adminRoutes = [ROUTES.CONTACTS, ROUTES.USERS, ROUTES.SITES, ROUTES.ORGANIZATIONS];
  const unauthorizedRouts = [ROUTES.SIGN_IN, ROUTES.FORGOT_PASSWORD, ROUTES.CHANGE_PASSWORD, ROUTES.RELEASE_NOTES];

  return (
    <Route
      {...routeProps}
      render={props => {
        if (loggedIn && unauthorizedRouts.includes(location.pathname)) {
          return <Redirect to={{ pathname: redirectPath || ROUTES.CONNECT }} />;
        }
        if (!loggedIn && !unauthorizedRouts.includes(location.pathname)) {
          return <Redirect to={{ pathname: redirectPath || ROUTES.SIGN_IN }} />;
        }
        if (location.pathname === ROUTES.TWO_FACTOR) {
          return <Component {...props} />;
        }
        if (!user?.isTwoFactorAuthorized && !unauthorizedRouts.includes(location.pathname)) {
          return <Redirect to={{ pathname: redirectPath || ROUTES.TWO_FACTOR }} />;
        }
        if (
          location.pathname === ROUTES.MONTHLY_BILLING &&
          user.role !== 'super_admin' &&
          user.role !== 'system_admin'
        ) {
          return <Redirect to={{ pathname: redirectPath || ROUTES.CONNECT }} />;
        }
        if (location.pathname === ROUTES.DEVICES && !checkIfPermissionIsGranted('devices', user, ['distributor'])) {
          return <Redirect to={{ pathname: redirectPath || ROUTES.CONNECT }} />;
        }
        if (location.pathname === ROUTES.MESSAGING && !checkIfPermissionIsGranted('messaging', user)) {
          return <Redirect to={{ pathname: redirectPath || ROUTES.CONNECT }} />;
        }
        if (location.pathname === ROUTES.TEST && !checkIfPermissionIsGranted('test', user)) {
          return <Redirect to={{ pathname: redirectPath || ROUTES.TEST }} />;
        }
        if (adminRoutes.includes(location.pathname) && !checkIfPermissionIsGranted('admin', user)) {
          return <Redirect to={{ pathname: redirectPath || ROUTES.TEST }} />;
        }
        return <Component {...props} />;
      }}
    />
  );
};

const mapStateToProps = state => {
  return {
    loggedIn: !!state.user,
    user: state.user,
  };
};

export default connect(mapStateToProps)(ProtectedRoute);
