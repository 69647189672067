import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import { APP_GREEN_COLOR } from '../../../constants/const';
import HttpsIcon from '@mui/icons-material/Https';
import AdornedButton from '../../Button/AdornedButton';
import axios from 'axios';
import * as API from '../../../constants/api';
import Swal from 'sweetalert2';
import i18n from '../../../i18n';
import { canConnectToDevice, getErrorTextForTakeControl, showErrorAlert } from '../../../utils/common';
import { showToast } from '../../../store/actions/toastAction';
import { useDispatch, useSelector } from 'react-redux';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { getDaysRemaining, getFormatDate, parseDate } from '../const';
import LedIndicator from '../../Common/LedIndicator';
import TextField from '@mui/material/TextField';
import { getDocs, query, where, updateDoc } from 'firebase/firestore';
import { Link } from '@mui/material';

const PREFIX = 'DeviceInformationDialog';

const classes = {
  infoContainer: `${PREFIX}-infoContainer`,
  infoLeftContainer: `${PREFIX}-infoLeftContainer`,
  failingCheckList: `${PREFIX}-failingCheckList`,
  failingCheckLabel: `${PREFIX}-failingCheckLabel`,
  successFailingChecks: `${PREFIX}-successFailingChecks`,
};

const StyledDialog = styled(Dialog)(() => ({
  [`& .${classes.infoContainer}`]: {
    display: 'flex',
    marginBottom: '15px',
    alignItems: 'center',
  },

  [`& .${classes.infoLeftContainer}`]: {
    width: '150px',
  },

  [`& .${classes.failingCheckList}`]: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    flex: '1',
  },

  [`& .${classes.failingCheckLabel}`]: {
    whiteSpace: 'break-spaces',
    overflow: 'hidden',
    textOverflow: 'clip',
  },

  [`& .${classes.successFailingChecks}`]: {
    color: APP_GREEN_COLOR,
  },
}));

const DeviceInformationDialog = ({ show, toggler, device, firebase, reload }) => {
  const { t } = useTranslation();
  const [selectedDate, handleDateChange] = useState(null);
  const [gspNumber, setGspNumber] = useState(null);
  const [goldRegistrationNumber, setGolfRegistrationNumber] = useState(null);
  const [loading, setLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [getBackupLoading, setGetBackupLoading] = useState(false);
  const [failingChecks, setFailingChecks] = useState([]);

  const authUser = useSelector(state => state.user);
  const dispatch = useDispatch();

  const getFailingChecks = async () => {
    if (!device.id) {
      return;
    }
    setLoading(true);
    try {
      const failingChecksSnapshot = await getDocs(query(firebase.failingChecks(), where('deviceid', '==', device.id)));
      setFailingChecks(failingChecksSnapshot.docs.map(doc => doc.data()));
    } finally {
      setLoading(false);
    }
  };

  const getPassPhrase = async () => {
    setGetBackupLoading(true);

    try {
      const passphraseRes = (await axios.get(`${API.GET_COVE_BACKUP_DETAILS}?deviceId=${device.id}`)).data;
      if (passphraseRes) {
        await Swal.fire({
          icon: 'success',
          title: i18n.t('backup_details'),
          confirmButtonText: i18n.t('ok'),
          confirmButtonColor: APP_GREEN_COLOR,
          html: `Device Name: ${passphraseRes.name}<br/>Installation Key: ${
            passphraseRes.password
          }<br/>ReInstallation PassPhrase: ${passphraseRes.encryptionKey ?? ''}`,
        });
      } else {
        showErrorAlert('no_encryption_key_found');
      }
    } catch (err) {
      dispatch(showToast(getErrorTextForTakeControl(err), 'error'));
    } finally {
      setGetBackupLoading(false);
    }
  };

  useEffect(() => {
    getFailingChecks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (device.gsp_expiry) {
      handleDateChange(parseDate(device.gsp_expiry));
    }
    if (device.gsp_number) {
      setGspNumber(device.gsp_number);
    }
    if (device.golf_registration_number) {
      setGolfRegistrationNumber(device.golf_registration_number);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [device]);

  const saveDeviceInformation = async () => {
    setSaveLoading(true);
    try {
      const formattedDate = getFormatDate(selectedDate);
      await updateDoc(firebase.device(device.id), {
        gsp_expiry: formattedDate,
        gsp_number: gspNumber,
        golf_registration_number: goldRegistrationNumber,
      });
      handleDateChange(null);
      const updatedDevice = {
        ...device,
        gsp_expiry: formattedDate,
        gsp_expiry_in_days: getDaysRemaining(selectedDate),
        gsp_number: gspNumber,
        golf_registration_number: goldRegistrationNumber,
      };
      reload(updatedDevice);
      toggler(false);
    } catch (e) {
      dispatch(showToast(getErrorTextForTakeControl(e), 'error'));
    } finally {
      setSaveLoading(false);
    }
  };

  const onClose = () => {
    toggler(false);
  };

  const canEditGspExpiry = () => {
    return authUser.role !== 'distributor';
  };

  const onGspNumberChange = event => {
    event.preventDefault();
    setGspNumber(event.target.value);
  };

  const onGolfRegistrationNumberChange = event => {
    event.preventDefault();
    setGolfRegistrationNumber(event.target.value);
  };

  return (
    <StyledDialog open={show || false} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>{t('device_information')}</DialogTitle>
      <form>
        <DialogContent>
          {!loading ? (
            <div>
              <div className={classes.infoContainer}>
                <Typography variant="subtitle2" className={classes.infoLeftContainer}>
                  {t('name')}:
                </Typography>
                <Typography variant="subtitle2">{device.name}</Typography>
              </div>
              <div className={classes.infoContainer}>
                <Typography variant="subtitle2" className={classes.infoLeftContainer}>
                  {t('site')}:
                </Typography>
                <Typography variant="subtitle2">
                  {device.region?.toUpperCase()} - {device.clientName} - {device.siteName}
                </Typography>
              </div>
              <div className={classes.infoContainer}>
                <Typography variant="subtitle2" className={classes.infoLeftContainer}>
                  {t('serial_number')}:
                </Typography>
                <Typography variant="subtitle2">{device.device_serial}</Typography>
              </div>
              <div className={classes.infoContainer}>
                <Typography variant="subtitle2" className={classes.infoLeftContainer}>
                  {t('state')}:
                </Typography>
                <LedIndicator classes={device.deviceState} />
              </div>
              <div className={classes.infoContainer}>
                <Typography variant="subtitle2" className={classes.infoLeftContainer}>
                  {t('backup_status')}:
                </Typography>
                <LedIndicator classes={device.backupStatus} />
              </div>
              <div className={classes.infoContainer}>
                <Typography variant="subtitle2" className={classes.infoLeftContainer}>
                  {t('messaging_agent')}:
                </Typography>
                <LedIndicator classes={device.pulse === 'Online' ? 'green' : 'offline'} />
              </div>
              <div className={classes.infoContainer}>
                <Typography variant="subtitle2" className={classes.infoLeftContainer}>
                  {t('cirrus_pro_database_size_in_gb')}:
                </Typography>
                <Typography variant="subtitle2">{device.cirrus_pro_database_size_in_gb}</Typography>
              </div>
              <div className={classes.infoContainer}>
                <Typography variant="subtitle2" className={classes.infoLeftContainer}>
                  {t('last_response')}:
                </Typography>
                <Typography variant="subtitle2">
                  {device.last_response} ({device.last_response_in_days} {t('days')})
                </Typography>
              </div>
              <div className={classes.infoContainer}>
                <Typography variant="subtitle2" className={classes.infoLeftContainer}>
                  {t('warranty_expiry')}:
                </Typography>
                <Typography variant="subtitle2">
                  {device.warranty_expiry} ({device.warranty_expiry_in_days} {t('days')})
                </Typography>
              </div>
              {device.ngrokDomain ? (
                <div className={classes.infoContainer}>
                  <Typography variant="subtitle2" className={classes.infoLeftContainer}>
                    {t('ngrok_domain')}:
                  </Typography>
                  <Typography variant="subtitle2">
                    <Link href={device.ngrokDomain} target="_blank">
                      {device.ngrokDomain}
                    </Link>
                  </Typography>
                </div>
              ) : null}

              <div className={classes.infoContainer}>
                <Typography variant="subtitle2" className={classes.infoLeftContainer}>
                  {t('failing_checks')}:
                </Typography>
                <div className={classes.failingCheckList}>
                  {failingChecks.length !== 0 ? (
                    failingChecks.map((failingCheck, index) => (
                      <Typography variant="subtitle2" key={index} color="error" className={classes.failingCheckLabel}>
                        {failingCheck.description + ': ' + failingCheck.formatted_output}
                      </Typography>
                    ))
                  ) : (
                    <Typography variant="subtitle2" className={classes.successFailingChecks}>
                      {t('no_failing_checks_tasks')}
                    </Typography>
                  )}
                </div>
              </div>
              <div className={classes.infoContainer}>
                <Typography variant="subtitle2" className={classes.infoLeftContainer}>
                  {t('gsp_expiry')}:
                </Typography>
                <div>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      id="gsp-expiry"
                      views={['year', 'month', 'day']}
                      name="gsp-expiry"
                      fullWidth
                      onChange={canEditGspExpiry() ? handleDateChange : () => {}}
                      value={selectedDate}
                      disabled={loading || !canEditGspExpiry()}
                      renderInput={props => <TextField {...props} />}
                    />
                  </LocalizationProvider>
                </div>
              </div>
              <div className={classes.infoContainer}>
                <Typography variant="subtitle2" className={classes.infoLeftContainer}>
                  {t('gsp_expiry_in_days')}:
                </Typography>
                <Typography variant="subtitle2">{getDaysRemaining(selectedDate)}</Typography>
              </div>
              <div className={classes.infoContainer}>
                <Typography variant="subtitle2" className={classes.infoLeftContainer}>
                  {t('gsp_number')}:
                </Typography>
                <div>
                  <TextField
                    margin="dense"
                    id="gsp-number"
                    label={t('gsp_number')}
                    type="number"
                    fullWidth
                    onChange={onGspNumberChange}
                    value={gspNumber}
                  />
                </div>
              </div>
              <div className={classes.infoContainer}>
                <Typography variant="subtitle2" className={classes.infoLeftContainer}>
                  {t('golf_registration_number')}:
                </Typography>
                <div>
                  <TextField
                    margin="dense"
                    id="gsp-number"
                    label={t('golf_registration_number')}
                    type="number"
                    fullWidth
                    onChange={onGolfRegistrationNumberChange}
                    value={goldRegistrationNumber}
                  />
                </div>
              </div>
              <div className={classes.infoContainer}>
                <Typography variant="subtitle2" className={classes.infoLeftContainer}>
                  {t('backup_details')}:
                </Typography>
                <AdornedButton
                  variant="contained"
                  color="primary"
                  disabled={getBackupLoading || !canConnectToDevice(authUser, device)}
                  onClick={getPassPhrase}
                  loading={getBackupLoading}
                >
                  <HttpsIcon />
                  &nbsp;{t('get_backup_details')}
                </AdornedButton>
              </div>
            </div>
          ) : (
            <div style={{ textAlign: 'center' }}>
              <CircularProgress />
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <AdornedButton variant="contained" onClick={onClose} loading={loading || saveLoading}>
            {t('close')}
          </AdornedButton>
          <AdornedButton
            variant="contained"
            color="primary"
            onClick={saveDeviceInformation}
            disabled={loading || saveLoading}
            loading={saveLoading || loading}
          >
            {t('save')}
          </AdornedButton>
        </DialogActions>
      </form>
    </StyledDialog>
  );
};
export default DeviceInformationDialog;
