import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import _ from 'lodash';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import { useTranslation } from 'react-i18next';
import AdornedButton from '../../Button/AdornedButton';
import { showToast } from '../../../store/actions/toastAction';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import * as API from '../../../constants/api';
import { Checkbox, FormControlLabel } from '@mui/material';

const PREFIX = 'UpdateSite';

const classes = {
  select: `${PREFIX}-select`,
  divider: `${PREFIX}-divider`
};

const StyledDialog = styled(Dialog)((
  {
    theme
  }
) => ({
  [`& .${classes.select}`]: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
  },

  [`& .${classes.divider}`]: {
    margin: theme.spacing(1, 0),
  }
}));

export const getSettingsArrayFromSite = setting => {
  let settings = [];
  if (!setting) {
    return [];
  }
  if (typeof setting === 'string') {
    settings = setting.split('_');
  } else if (Array.isArray(setting)) {
    settings = setting;
  }
  settings = settings.filter(setting => setting !== 'off');
  return settings;
};

const UpdateSite = ({ site, reload, show, toggler }) => {

  const { t } = useTranslation();
  const [alertSettingsObj, setAlertSettingsObj] = useState({});
  const [featureSettingsObj, setFeatureSettingsObj] = useState({});

  const alertSettingArr = [
    { label: t('site_setting_email'), value: 'email' },
    { label: t('site_setting_sms'), value: 'sms' },
  ];
  const featureSettingArr = [
    { label: t('site_setting_report'), value: 'report' },
    { label: t('site_setting_alert'), value: 'alert' },
    { label: t('site_setting_connect'), value: 'connect' },
    { label: t('site_setting_tunnel'), value: 'tunnel' },
  ];
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const getSettingsObjFromSite = setting => {
    return getSettingsArrayFromSite(setting).reduce((acc, item) => ({ ...acc, [item]: true }), {});
  };

  useEffect(() => {
    setAlertSettingsObj(getSettingsObjFromSite(site?.alertSetting));
    setFeatureSettingsObj(getSettingsObjFromSite(site?.featureSetting));
  }, [site]);

  const onClose = () => {
    toggler(false);
  };

  const onCheckboxChange = (event, key) => {
    event.persist();
    if (event.target.name === 'featureSetting') {
      setFeatureSettingsObj(prevState => ({
        ...prevState,
        [key]: event.target.checked,
      }));
    } else if (event.target.name === 'alertSetting') {
      setAlertSettingsObj(prevState => ({
        ...prevState,
        [key]: event.target.checked,
      }));
    }
  };

  const updateSite = async event => {
    event.preventDefault();
    try {
      setLoading(true);
      const alertSetting = Object.keys(alertSettingsObj).filter(key => alertSettingsObj[key] === true);
      const featureSetting = Object.keys(featureSettingsObj).filter(key => featureSettingsObj[key] === true);
      const updatedSite = {
        ...site,
        alertSetting,
        featureSetting,
      };
      await axios.post(`${API.UPDATE_SITE}/${site.id}`, {
        alertSetting,
        featureSetting,
      });
      reload(updatedSite);
      dispatch(showToast(t('site_update_success')));
      toggler(false);
    } catch (e) {
      dispatch(showToast(`${e?.response?.data ?? e.message}`, 'error'));
    } finally {
      setLoading(false);
    }
  };

  return (
    <StyledDialog open={show || false} onClose={onClose} fullWidth maxWidth="xs">
      <DialogTitle>{t('update_site')}</DialogTitle>
      <form noValidate onSubmit={updateSite}>
        <DialogContent>
          <div className={classes.select}>
            <InputLabel id="feature-select-label">{t('feature_setting')}</InputLabel>
            {_.map(featureSettingArr, feature => (
              <FormControlLabel
                key={feature.value}
                control={
                  <Checkbox
                    checked={featureSettingsObj[feature.value] === true}
                    onChange={ev => onCheckboxChange(ev, feature.value)}
                    name="featureSetting"
                  />
                }
                label={feature.label}
              />
            ))}
          </div>
          <div className={classes.select}>
            <InputLabel id="feature-select-label">{t('alert_setting')}</InputLabel>
            {_.map(alertSettingArr, setting => (
              <FormControlLabel
                key={setting.value}
                control={
                  <Checkbox
                    checked={alertSettingsObj[setting.value] === true}
                    onChange={ev => onCheckboxChange(ev, setting.value)}
                    name="alertSetting"
                  />
                }
                label={setting.label}
              />
            ))}
          </div>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={onClose} disabled={loading}>
            {t('close')}
          </Button>
          <AdornedButton
            variant="contained"
            color="primary"
            type="submit"
            disabled={Boolean(loading)}
            loading={loading}
          >
            {t('update')}
          </AdornedButton>
        </DialogActions>
      </form>
    </StyledDialog>
  );
};
export default UpdateSite;
