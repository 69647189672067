import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import _ from 'lodash';

import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import CircularProgress from '@mui/material/CircularProgress';
import Link from '@mui/material/Link';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import MaterialTable from '../MaterialTable/MaterialTable';
import { useTranslation } from 'react-i18next';
import { withFirebase } from '../Firebase';
import TableIcons from '../../constants/tableIcons';
import { REGION_LOOKUP } from '../../utils/common';
import AddSiteToDistributor from './dialogs/AddSiteToDistributor';
import RemoveSiteFromDistributor from './dialogs/RemoveSiteFromDistributor';
import UpdateUser from '../Users/dialogs/UpdateUser';
import UpdateSite from '../Sites/dialogs/UpdateSite';
import { getDoc, getDocs, query, updateDoc, where } from 'firebase/firestore';
import { arrayRemove, arrayUnion } from '@firebase/firestore';

const PREFIX = 'index';

const classes = {
  link: `${PREFIX}-link`,
  select: `${PREFIX}-select`,
  siteLine: `${PREFIX}-siteLine`,
  siteRemove: `${PREFIX}-siteRemove`,
  siteBlock: `${PREFIX}-siteBlock`,
  siteName: `${PREFIX}-siteName`,
};

const StyledTable = styled(MaterialTable)(() => ({
  [`& .${classes.link}`]: {
    cursor: 'pointer',
    fontWeight: 'bold',
  },
}));

const StyledSiteCell = styled('div')(() => ({
  [`& .${classes.siteLine}`]: {
    display: 'flex',
  },

  [`& .${classes.siteRemove}`]: {
    display: 'inline-block',
    verticalAlign: 'top',
    float: 'right',
  },

  [`& .${classes.siteBlock}`]: {
    marginBottom: 10,
    borderBottom: '1px solid #e0e0e0',
  },

  [`& .${classes.siteName}`]: {
    cursor: 'pointer',
    display: 'block',
    fontSize: 14,
  },
}));

const DistributorsPage = props => {
  const [distributors, setDistributors] = useState([]);
  const [sites, setSites] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [addingToDistributor, setAddingToDistributor] = useState(null);
  const [removingFromDistributor, setRemovingFromDistributor] = useState(null);
  const [siteToRemove, setSiteToRemove] = useState(null);
  const [updatingDistributor, setUpdatingDistributor] = useState(null);
  const [updatingSite, setUpdatingSite] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = () =>
    Promise.all([getDistributors(), getSites(), getOrganizations()])
      .then(([distributors, sites, organizations]) => {
        setDistributors(distributors);
        setSites(sites);
        setOrganizations(organizations);
      })
      .finally(() => setLoading(false));

  const getDistributors = async () => {
    const distributorsRef = await getDocs(query(props.firebase.users(), where('role', '==', 'distributor')));

    return await Promise.all(
      distributorsRef.docs.map(async distributor => {
        const sites = await getDocs(
          query(props.firebase.sites(), where('distributors', 'array-contains', distributor.ref)),
        );
        return {
          ...distributor.data(),
          uid: distributor.id,
          sites: sites.docs.map(site => {
            return { ...site.data(), uid: site.id };
          }),
        };
      }),
    );
  };

  const getSites = async () => {
    return (await getDocs(props.firebase.sites())).docs.map(site => {
      return {
        ...site.data(),
        uid: site.id,
      };
    });
  };

  const getOrganizations = async () => {
    return (await getDocs(props.firebase.organizations())).docs.map(organization => {
      return { ...organization.data(), uid: organization.id };
    });
  };

  const removeSiteFromDistributor = async (distributor, site) => {
    const distributorRef = await getDoc(props.firebase.user(distributor.uid));

    await updateDoc(props.firebase.site(site.uid), {
      distributors: arrayRemove(distributorRef.ref),
    });

    setDistributors(
      distributors.map(userData =>
        userData.uid === distributor.uid
          ? {
              ...userData,
              sites: userData.sites.filter(uSite => uSite.uid !== site.uid),
            }
          : userData,
      ),
    );
  };

  const addSiteToDistributor = async (distributor, site) => {
    const distributorRef = await getDoc(props.firebase.user(distributor.uid));

    await updateDoc(props.firebase.site(site.uid), {
      distributors: arrayUnion(distributorRef.ref),
    });

    setDistributors(
      distributors.map(dist =>
        dist.uid === distributor.uid
          ? {
              ...dist,
              sites: _.unionBy(dist.sites, [site], val => val.uid),
            }
          : dist,
      ),
    );
  };

  // eslint-disable-next-line no-unused-vars
  const updateDistributor = distributor => {
    setDistributors(distributors.map(d => (d.uid === distributor.uid ? distributor : d)));
  };

  const closeAddSiteDialog = () => {
    setAddingToDistributor(null);
  };

  const closeRemoveSiteDialog = () => {
    setSiteToRemove(null);
    setRemovingFromDistributor(null);
  };

  const closeUpdateUserDialog = () => {
    setUpdatingDistributor(null);
  };

  const closeUpdateSiteDialog = () => {
    setUpdatingSite(null);
  };

  return (
    <Container maxWidth="md">
      {loading ? (
        <div style={{ textAlign: 'center' }}>
          <CircularProgress />
        </div>
      ) : (
        <>
          {!!addingToDistributor && sites && (
            <AddSiteToDistributor
              addSite={addSiteToDistributor}
              distributor={addingToDistributor}
              sites={sites}
              onClose={closeAddSiteDialog}
            />
          )}

          {siteToRemove && removingFromDistributor && (
            <RemoveSiteFromDistributor
              distributor={removingFromDistributor}
              site={siteToRemove}
              onClose={closeRemoveSiteDialog}
              removeSite={removeSiteFromDistributor}
            />
          )}

          {updatingDistributor && (
            <UpdateUser
              reload={fetchData}
              user={updatingDistributor}
              setUser={setUpdatingDistributor}
              firebase={props.firebase}
              sites={sites}
              organizations={organizations}
              show={!!updatingDistributor}
              toggler={closeUpdateUserDialog}
            />
          )}

          {updatingSite && (
            <UpdateSite
              site={updatingSite}
              show={!!updatingSite}
              toggler={closeUpdateSiteDialog}
              firebase={props.firebase}
            />
          )}

          <DistributorsTable
            data={distributors}
            setAddingToDistributor={setAddingToDistributor}
            setRemovingFromDistributor={setRemovingFromDistributor}
            setSiteToRemove={setSiteToRemove}
            setUpdatingDistributor={setUpdatingDistributor}
            setUpdatingSite={setUpdatingSite}
          />
        </>
      )}
    </Container>
  );
};

const DistributorsTable = ({
  data,
  setAddingToDistributor,
  setRemovingFromDistributor,
  setSiteToRemove,
  setUpdatingDistributor,
  setUpdatingSite,
}) => {
  const { t } = useTranslation();

  const columns = [
    {
      title: t('email'),
      field: 'email',
      render: rowData => {
        return (
          <Link
            className={classes.link}
            onClick={() => {
              setUpdatingDistributor(rowData);
            }}
          >
            {rowData.email || ''}
          </Link>
        );
      },
    },
    {
      title: t('contact name'),
      field: 'name',
    },
    {
      title: t('organization'),
      field: 'organization_name',
    },
    {
      title: t('sites'),
      field: 'sites',
      width: 500,
      render: rowData => (
        <div>
          {rowData.sites.map(site => (
            <SiteCell
              key={site.uid}
              site={site}
              setSiteToRemove={setSiteToRemove}
              distributor={rowData}
              setRemovingFromDistributor={setRemovingFromDistributor}
              setUpdatingSite={setUpdatingSite}
            />
          ))}
          <IconButton onClick={() => addSiteToDistributor(rowData)} size="large">
            <AddIcon />
          </IconButton>
        </div>
      ),
    },
    {
      title: t('region'),
      field: 'region',
      lookup: REGION_LOOKUP,
      render: rowData => rowData.region.toUpperCase(),
    },
  ];

  const addSiteToDistributor = distributor => {
    setAddingToDistributor(distributor);
  };

  return (
    <StyledTable
      icons={TableIcons}
      title={t('distributors')}
      columns={columns}
      data={data}
      options={{ filtering: true }}
    />
  );
};

const SiteCell = ({ site, setSiteToRemove, setRemovingFromDistributor, setUpdatingSite, distributor }) => {
  const [hover, setHover] = useState(false);

  const handleSiteDelete = () => {
    setSiteToRemove(site);
    setRemovingFromDistributor(distributor);
  };

  // eslint-disable-next-line no-unused-vars
  const handleSiteNameClick = () => {
    setUpdatingSite(site);
  };

  return (
    <StyledSiteCell
      className={classes.siteBlock}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <div className={classes.siteLine}>
        <div>
          {/* TODO implement this */}
          {/* <Link className={classes.siteName} onClick={handleSiteNameClick}> */}
          <Link className={classes.siteName}>{site.name || ''}</Link>
          <Typography variant="caption">{`${site.region?.toUpperCase()} - ${site.clientName}` || ''}</Typography>
        </div>
        {hover ? (
          <div className={classes.siteRemove}>
            <IconButton size="small" onClick={handleSiteDelete}>
              <HighlightOffIcon color="error" />
            </IconButton>
          </div>
        ) : null}
      </div>
    </StyledSiteCell>
  );
};

export default withFirebase(DistributorsPage);
