import React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
const PREFIX = 'AdornedButton';

const classes = {
  spinner: `${PREFIX}-spinner`
};

const StyledButton = styled(Button)({
  [`& .${classes.spinner}`]: {
    marginLeft: 5,
  },
});

const SpinnerAdornment = () => {

  return <CircularProgress className={classes.spinner} size={20} />;
};
const AdornedButton = props => {
  const { children, loading, ...rest } = props;
  return (
    <StyledButton {...rest} disabled={rest.disabled || loading}>
      {children}
      {loading && <SpinnerAdornment {...rest} />}
    </StyledButton>
  );
};
export default AdornedButton;
