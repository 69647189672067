import React, { useState } from 'react';
import _ from 'lodash';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Autocomplete from '@mui/material/Autocomplete';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { showToast } from '../../../store/actions/toastAction';
import AdornedButton from '../../Button/AdornedButton';
import { updateDoc } from 'firebase/firestore';
import { arrayUnion } from '@firebase/firestore';

const AddUserToSite = ({ users, site, firebase, show, toggler, reload }) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [userToAdd, setUserToAdd] = useState({});
  const usersArray = _.filter(users, user => (site.administrators ? !site.administrators.includes(user.uid) : true));

  const addUser = async () => {
    if (!userToAdd?.uid) {
      return;
    }
    try {
      setLoading(true);
      await updateDoc(firebase.site(site.uid), {
        administrators: arrayUnion(userToAdd.uid),
      });
      await reload();
      dispatch(showToast(t('user_add_to_site_success')));
      toggler(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog
      open={show}
      onClose={() => {
        toggler(false);
      }}
    >
      <DialogTitle>{`${site.clientName || ''} - ${site.name || ''} - ${t('add administrator')}`}</DialogTitle>
      <DialogContent>
        <Autocomplete
          id="users-list"
          options={usersArray}
          getOptionLabel={option => `${option.name || ''} - ${option.email || ''}`}
          onChange={(event, newValue) => {
            setUserToAdd(newValue);
          }}
          renderInput={params => <TextField {...params} label={t('users')} variant="standard" />}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={() => toggler(false)}>
          {t('cancel')}
        </Button>
        <AdornedButton
          variant="contained"
          color="primary"
          onClick={() => addUser()}
          disabled={loading}
          loading={loading}
        >
          {t('add')}
        </AdornedButton>
      </DialogActions>
    </Dialog>
  );
};
export default AddUserToSite;
