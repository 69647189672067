import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import customProtocolCheck from '../Connect/custom-protocol-check';
import getOs from '../../utils/userOs';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
const PREFIX = 'ConnectDialog';

const classes = {
  modal: `${PREFIX}-modal`,
  icon: `${PREFIX}-icon`,
  buttonContainer: `${PREFIX}-buttonContainer`,
};

const StyledDialog = styled(Dialog)(() => ({
  [`&.${classes.modal}`]: {
    padding: 20,
  },

  [`& .${classes.icon}`]: {
    verticalAlign: 'middle',
  },

  [`& .${classes.buttonContainer}`]: {
    justifyContent: 'space-between',
    paddingLeft: '30px',
    paddingRight: '30px',
  },
}));

const downloadLinks = {
  win: 'https://www.system-monitor.com/dashboard/rap.php?Action=downloadtcviewermspanywherewin',
  mac: 'https://www.system-monitor.com/dashboard/rap.php?Action=downloadtcviewermspanywhereosx',
  android: 'https://play.google.com/store/apps/details?id=sw.androidviewer&hl=en_US',
  ios: 'https://apps.apple.com/app/n-able-rmm/id1104242696',
};

const ConnectDialog = ({ controlUrl }) => {
  const { t } = useTranslation();
  const [downloadUrl, setDownloadUrl] = useState(downloadLinks[getOs()]);
  const [showDialog, setShowDialog] = useState(false);

  const handleClose = () => {
    setShowDialog(false);
  };

  useEffect(() => {
    if (controlUrl) {
      customProtocolCheck(
        controlUrl,
        url => {
          url && setDownloadUrl(url);
          setShowDialog(true);
        },
        () => {
          setShowDialog(false);
        },
        10000,
        () => {},
        downloadUrl,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [controlUrl]);

  return (
    <StyledDialog onClose={handleClose} className={classes.modal} open={showDialog}>
      <DialogContent className={classes.modal}>
        <div>
          <Typography>{t('take control not installed ios')}</Typography>
        </div>
      </DialogContent>
      <DialogActions classes={{ root: classes.buttonContainer }}>
        <Button href={downloadUrl} color="primary" variant="contained">
          {t('install')}
        </Button>
        <Button onClick={handleClose} variant="contained">
          {t('close')}
        </Button>
      </DialogActions>
    </StyledDialog>
  );
};
export default ConnectDialog;
