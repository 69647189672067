import React from 'react';
import { styled } from '@mui/material/styles';
import { APP_GREEN_COLOR, APP_RED_COLOR } from '../../constants/const';
import { statusTitle } from '../Device/status-bar';
import Tooltip from '@mui/material/Tooltip';

const PREFIX = 'LedIndicator';

const classes = {
  led: `${PREFIX}-led`,
  green: `${PREFIX}-green`,
  healthy: `${PREFIX}-healthy`,
  orange: `${PREFIX}-orange`,
  last30_backup: `${PREFIX}-last30_backup`,
  grey: `${PREFIX}-grey`,
  red: `${PREFIX}-red`,
  no_backup: `${PREFIX}-no_backup`,
  offline: `${PREFIX}-offline`,
  off: `${PREFIX}-off`,
};

const StyledDiv = styled('div')(() => ({
  [`&.${classes.led}`]: {
    width: 24,
    height: 24,
    backgroundColor: '#e6e6e6',
    borderRadius: '50%',

    display: 'inline-block',
    verticalAlign: 'middle',
    marginRight: 10,
  },

  [`&.${classes.green}`]: {
    backgroundColor: APP_GREEN_COLOR,
  },

  [`&.${classes.healthy}`]: {
    backgroundColor: APP_GREEN_COLOR,
  },

  [`&.${classes.orange}`]: {
    backgroundColor: '#FDC110',
  },

  [`&.${classes.last30_backup}`]: {
    backgroundColor: '#FDC110',
  },

  [`&.${classes.grey}`]: {
    backgroundColor: '#555',
  },

  [`&.${classes.red}`]: {
    backgroundColor: APP_RED_COLOR,
  },

  [`&.${classes.no_backup}`]: {
    backgroundColor: APP_RED_COLOR,
  },

  [`&.${classes.offline}`]: {
    backgroundColor: APP_RED_COLOR,
  },
  [`&.${classes.off}`]: {
    backgroundColor: APP_RED_COLOR,
  },
}));

const LedIndicator = props => {
  return (
    <Tooltip title={statusTitle[props.classes]}>
      <StyledDiv className={`${classes.led} ${classes[props.classes]}`} />
    </Tooltip>
  );
};
export default LedIndicator;
