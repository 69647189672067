import React, { useCallback, useEffect, useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import Container from '@mui/material/Container';
import CircularProgress from '@mui/material/CircularProgress';
import { withFirebase } from '../Firebase';
import { useTranslation } from 'react-i18next';
import LedIndicator from '../Common/LedIndicator';
import { algoliasearch } from 'algoliasearch';
import 'jspdf-autotable';
import {
  canConnectToDevice,
  getAllowedRegions,
  getErrorTextForTakeControl,
  getErrorTextForTunnel,
  getTakeControlURL,
  isAuthUserAdmin,
  isDistributorAccessEnabled,
  isOffline,
  REGION_LOOKUP,
} from '../../utils/common';
import { useDispatch, useSelector } from 'react-redux';
import { showToast } from '../../store/actions/toastAction';
import {
  DEVICE_EXPORT_COLUMNS,
  displayExpiryInDays,
  getDeviceExportRowData,
  getSoftwareAssetExportRowData,
  mapDeviceData,
  SOFTWARE_ASSET_EXPORT_COLUMNS,
} from './const';
import axios from 'axios';
import * as API from '../../constants/api';
import { GET_ACTIVE_TUNNELS } from '../../constants/api';
import Link from '@mui/material/Link';
import DeviceInformationDialog from './dialogs/DeviceInformationDialog';
import DeviceTable from './device-table';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SettingsEthernetIcon from '@mui/icons-material/SettingsEthernet';
import CastIcon from '@mui/icons-material/Cast';
import BackupIcon from '@mui/icons-material/Backup';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import { StyledMenu } from '../Common/NavDropDown';
import ConnectDialog from '../Connect/connect-dialog';
import StatusBar from './status-bar';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import { CardContent } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import FilterIcon from '@mui/icons-material/Filter';
import { getDoc, getDocs, query, where } from 'firebase/firestore';
import _ from 'lodash';
import { regionsWithAll } from '../../constants/regions';
import Select from '@mui/material/Select';
import AlgoliaQueryBuilder from 'algolia-search-builder';
import { CsvBuilder } from 'filefy';
import AppSearchBar from '../Common/AppSearchBar';
import useInterval from '../../utils/useInterval';

const PREFIX = 'index';

const classes = {
  table: `${PREFIX}-table`,
  tableFilter: `${PREFIX}-tableFilter`,
  regionSelect: `${PREFIX}-regionSelect`,
  searchBar: `${PREFIX}-searchBar`,
  link: `${PREFIX}-link`,
  connectContainer: `${PREFIX}-connectContainer`,
  nameContainer: `${PREFIX}-nameContainer`,
  clearFilterView: `${PREFIX}-clearFilterView`,
  spinnerView: `${PREFIX}-spinnerView`,
  spinnerContent: `${PREFIX}-spinnerContent`,
  warningLastResponseContainer: `${PREFIX}-warningLastResponseContainer`,
  dashboard: `${PREFIX}-dashboard`,
  dashboardCard: `${PREFIX}-dashboardCard`,
  dashboardSubtitle: `${PREFIX}-dashboardSubtitle`,
  distributorAccessButton: `${PREFIX}-distributorAccessButton`,
  distributorAccessButtonWarning: `${PREFIX}-distributorAccessButtonWarning`,
  distributorLine: `${PREFIX}-distributorLine`,
  distributorBlock: `${PREFIX}-distributorBlock`,
  distributorName: `${PREFIX}-distributorName`,
};

const StyledContainer = styled(Container)(({ theme }) => ({
  [`& .${classes.table}`]: {
    margin: theme.spacing(2, 0),
  },
  [`& .${classes.tableFilter}`]: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '15px',
  },
  [`& .${classes.spinnerView}`]: {
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    background: 'white',
    opacity: 0.3,
  },
  [`& .${classes.spinnerContent}`]: {
    position: 'absolute',
    top: '50%',
    left: '50%',
  },
  [`& .${classes.regionSelect}`]: {
    gap: '15px',
    marginLeft: '15px',
    display: 'flex',
    alignItems: 'center',
    width: '300px',
  },
  [`& .${classes.searchBar}`]: {
    marginLeft: '15px',
  },

  [`& .${classes.link}`]: {
    cursor: 'pointer',
    fontWeight: 'bold',
  },

  [`& .${classes.connectContainer}`]: {
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
  },

  [`& .${classes.nameContainer}`]: {
    display: 'flex',
    alignItems: 'center',
  },

  [`& .${classes.clearFilterView}`]: {
    display: 'flex',
    justifyContent: 'end',
    marginBottom: '15px',
    gap: '20px',
  },

  [`& .${classes.warningLastResponseContainer}`]: {
    color: '#fbc02d',
    fontWeight: 'bold',
  },

  [`& .${classes.dashboard}`]: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'start',
    gap: '30px',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },

  [`& .${classes.dashboardCard}`]: {
    flex: 1,
    width: '100%',
  },

  [`& .${classes.dashboardSubtitle}`]: {
    fontSize: '12px',
    marginBottom: '15px',
  },

  [`& .${classes.distributorAccessButton}`]: {
    textWrap: 'nowrap',
    marginTop: '4px',
  },

  [`& .${classes.distributorAccessButtonWarning}`]: {
    backgroundColor: '#ffa726',
  },

  [`& .${classes.distributorLine}`]: {
    display: 'flex',
  },

  [`& .${classes.distributorBlock}`]: {
    marginBottom: 10,
    borderBottom: '1px solid #e0e0e0',
  },

  [`& .${classes.distributorName}`]: {
    cursor: 'pointer',
    display: 'block',
    fontSize: 14,
  },
}));

const initialFilterByStatus = { state: [], backup: [], warranty: [] };

const DevicePage = props => {
  const client = algoliasearch(process.env.REACT_APP_ALOGORIA_APP_ID, process.env.REACT_APP_ALOGORIA_API_KEY);
  const { t } = useTranslation();
  const [failingChecks, setFailingChecks] = useState([]);
  const [pulseOnlineDevices, setPulseOnlineDevices] = useState([]);
  const failingChecksRef = useRef(failingChecks);
  const authUser = useSelector(state => state.user);
  const [loading, setLoading] = useState(true);
  const [isDeviceInfoModalOpen, setIsDeviceInfoModalOpen] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [devices, setDevices] = useState([]);
  const [filterByStatus, setFilterByStatus] = useState(initialFilterByStatus);
  const [showFilter, setShowFilter] = useState(true);
  const [tunnels, setTunnels] = useState([]);
  const [distributors, setDistributors] = useState({});
  const [facetResult, setFacetResult] = useState({});

  const distributorsRef = useRef(distributors);

  const dispatch = useDispatch();
  const [manualDevices, setManualDevices] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [connecting, setConnecting] = useState(false);
  const [takeControlUrl, setTakeControlUrl] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedDeviceForDropdown, setSelectedDeviceForDropdown] = useState(null);
  const [selectedRegion, setSelectedRegion] = useState('');
  const [distributorSiteIds, setDistributorSiteIds] = useState([]);
  const [fetchedAlgoliaDeviceIds, setFetchedAlgoliaDeviceIds] = useState([]);

  const pulseOnlineDevicesRef = useRef(pulseOnlineDevices);
  const distributorSiteIdsRef = useRef(distributorSiteIds);
  const tunnelsRef = useRef(tunnels);

  const [triggerRefresh, setTriggerRefresh] = useState(false);
  const [algoliaFilter, setAlgoliaFilter] = useState(null);
  const [showSpinner, setShowSpinner] = useState(false);
  const [tableSearch, setTableSearch] = useState('');

  const tableRef = useRef();

  const onSelectChange = event => {
    event.preventDefault();
    setSelectedRegion(event.target.value);
  };

  useEffect(() => {
    pulseOnlineDevicesRef.current = pulseOnlineDevices;
  }, [pulseOnlineDevices]);

  useEffect(() => {
    distributorSiteIdsRef.current = distributorSiteIds;
  }, [distributorSiteIds]);

  useEffect(() => {
    tunnelsRef.current = tunnels;
  }, [tunnels]);

  useEffect(() => {
    distributorsRef.current = distributors;
  }, [distributors]);

  const openDeviceInfoModal = deviceData => {
    setIsDeviceInfoModalOpen(true);
    setSelectedDevice(deviceData);
  };

  const handleActionButtonClick = (event, device) => {
    event.stopPropagation(); // Add this line
    setAnchorEl(event.currentTarget);
    setSelectedDeviceForDropdown(device);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const canConnectoToTunnel = device => {
    return isAuthUserAdmin(authUser) || isDistributorAccessEnabled(device);
  };

  const customNumberFilterAndSearch = field => (term, rowData) => {
    let value = rowData[field];
    if (term.startsWith('>')) {
      return value > parseInt(term.substring(1), 10);
    } else if (term.startsWith('<')) {
      return value < parseInt(term.substring(1), 10);
    }
    return value === parseInt(term, 10);
  };

  const isFieldHidden = field => {
    return localStorage.getItem(`field_${field}_hidden`) === 'true';
  };

  const initColumns = [
    {
      title: t('region'),
      field: 'region',
      width: '60px',
      lookup: REGION_LOOKUP,
      render: rowData => <div>{rowData.region?.toUpperCase()}</div>,
    },
    {
      title: t('client'),
      field: 'clientName',
    },
    {
      title: t('site'),
      field: 'siteName',
    },
    {
      title: t('name'),
      field: 'name',
      render: rowData => (
        <Link className={classes.link} onClick={() => openDeviceInfoModal(rowData)}>
          {rowData.name || ''}
        </Link>
      ),
    },
    {
      title: t('rain_bird_software'),
      field: 'software',
    },
    {
      title: t('version'),
      field: 'version',
    },
    {
      title: t('gsp_expiry'),
      field: 'gsp_expiry',
      render: rowData => (
        <>
          {rowData.gsp_expiry} {displayExpiryInDays(rowData.gsp_expiry_in_days)}
        </>
      ),
    },
    {
      title: t('cirrus_pro_database_size_in_gb'),
      field: 'cirrus_pro_database_size_in_gb',
      customFilterAndSearch: customNumberFilterAndSearch('cirrus_pro_database_size_in_gb'),
    },
    {
      title: t('warranty_expiry'),
      field: 'warranty_expiry',
      render: rowData => (
        <>
          {rowData.warranty_expiry} {displayExpiryInDays(rowData.warranty_expiry_in_days)}
        </>
      ),
    },
    {
      title: t('last_response'),
      field: 'last_response',
      render: rowData => (
        <>
          {rowData.last_response} {displayExpiryInDays(rowData.last_response_in_days)}
        </>
      ),
    },
    {
      title: t('state'),
      field: 'deviceState',
      width: '50px',
      render: rowData => <LedIndicator classes={rowData.deviceState} />,
      lookup: {
        green: 'Online',
        orange: 'Warning',
        red: 'Fail',
        grey: 'Offline',
      },
    },
    {
      title: t('backup_status'),
      field: 'backupStatus',
      width: '50px',
      render: rowData => <LedIndicator classes={rowData.backupStatus} />,
      lookup: {
        healthy: 'Healthy',
        last30_backup: 'Backup In past 30 days',
        no_backup: 'No Backups',
      },
    },
    {
      title: t('messaging_agent'),
      field: 'pulse',
      width: '50px',
      render: rowData => (
        <div>
          <LedIndicator classes={rowData.pulse === 'Online' ? 'green' : 'offline'} />
        </div>
      ),
      lookup: {
        Online: 'Online',
        Offline: 'Offline',
      },
    },
    {
      title: t('distributors'),
      field: 'distributors',
      width: 500,
      hidden: authUser.role === 'distributor',
      hiddenByColumnsButton: authUser.role === 'distributor',
      render: rowData => (
        <div>
          {distributorsRef.current[`${rowData.region}.${rowData.siteid}`]?.map(distributor => (
            <div key={distributor.id} className={classes.distributorBlock}>
              <div className={classes.distributorLine}>
                <div>
                  <Link className={classes.distributorName}>{distributor.name || ''}</Link>
                  <Typography variant="caption">{distributor.email || ''}</Typography>
                </div>
              </div>
            </div>
          ))}
        </div>
      ),
    },
    {
      title: t('tunnel'),
      field: 'tunnelStatus',
      lookup: {
        ENABLED: 'ENABLED',
        DISABLED: 'DISABLED',
      },
    },
    {
      title: t('ngrok_service'),
      field: 'ngrokStatus',
      lookup: {
        Online: 'Online',
        Offline: 'Offline',
      },
      render: rowData => (
        <div>
          <LedIndicator classes={rowData.ngrokStatus} />
        </div>
      ),
    },
    {
      title: t('connect'),
      render: rowData => (
        <Button
          onClick={e => handleActionButtonClick(e, rowData)}
          endIcon={<KeyboardArrowDownIcon />}
          aria-controls={anchorEl ? 'demo-customized-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={anchorEl ? 'true' : undefined}
          variant="contained"
          color={isOffline(rowData) ? 'grey' : 'primary'}
          disableElevation
          aria-label="actions"
        >
          {t('actions')}
        </Button>
      ),
    },
  ];
  const columnsWithHidden = initColumns.map(column => {
    return {
      ...column,
      hidden: isFieldHidden(column.field),
    };
  });

  // Use useState to fix the material table filter reset issue when modal is present
  const [columns] = useState(columnsWithHidden);

  async function fetchFailingChecks() {
    const checksSnapshot = await getDocs(props.firebase.failingChecks());
    failingChecksRef.current = checksSnapshot.docs.map(doc => doc.data());
    setFailingChecks(checksSnapshot.docs.map(doc => doc.data()));
  }

  async function fetchOnlinePulseDevices() {
    try {
      const res = await axios.get(`${API.GET_PULSE_ONLINE_DEVICES}?environment=${process.env.REACT_APP_ENVIRONMENT}`);
      setPulseOnlineDevices(res.data.deviceIds);
    } catch (e) {
      console.error(e);
    }
  }

  async function fetchDistributors() {
    if (authUser.role === 'distributor') {
      const distributorRef = await getDoc(props.firebase.user(authUser.uid));

      const sitesQuery = query(props.firebase.sites(), where('distributors', 'array-contains', distributorRef.ref));

      const siteSnapshot = await getDocs(sitesQuery);
      setDistributorSiteIds(siteSnapshot.docs.map(doc => doc.id.split('.')[1]));
      return;
    }

    const siteDocs = await getDocs(props.firebase.sites());
    setDistributors(
      Object.fromEntries(
        await Promise.all(
          siteDocs.docs.map(async doc => [
            doc.id,
            await Promise.all(
              (doc.data().distributors || []).map(async distributor => {
                return {
                  id: distributor.id,
                  ...(await getDoc(distributor)).data(),
                };
              }),
            ),
          ]),
        ),
      ),
    );
  }

  async function getActiveTunnels() {
    try {
      const res = await axios.get(GET_ACTIVE_TUNNELS);
      setTunnels(res.data);
    } catch (e) {
      console.error(`Error occurred while fetching active tunnels. Error: ${e}`);
    }
  }

  async function fetchData() {
    try {
      await Promise.all([fetchFailingChecks(), fetchOnlinePulseDevices(), getActiveTunnels(), fetchDistributors()]);
      setLoading(false);
    } catch (error) {
      dispatch(showToast(`${error}`, 'error'));
      setLoading(false);
    }
  }

  const connectToServer = async () => {
    setAnchorEl(null);
    setConnecting(true);

    try {
      const url = await getTakeControlURL(authUser, selectedDeviceForDropdown, 'admin_control');
      setTakeControlUrl(url);
    } catch (err) {
      dispatch(showToast(getErrorTextForTakeControl(err), 'error'));
    } finally {
      setConnecting(false);
    }
  };

  const connectToTunnel = async () => {
    setAnchorEl(null);

    if (!selectedDeviceForDropdown?.tunnel) {
      dispatch(showToast('Ngrok is not enabled for this device', 'error'));
      return;
    }

    setConnecting(true);

    try {
      const tunnelUrl = await axios.post(API.START_TUNNEL, { deviceId: selectedDeviceForDropdown.id });
      window.open(`https://${tunnelUrl.data.url}`, '_blank');
    } catch (err) {
      dispatch(showToast(getErrorTextForTunnel(err), 'error'));
    } finally {
      setConnecting(false);
    }
  };

  const launchBackupManager = async () => {
    setAnchorEl(null);
    setConnecting(true);

    try {
      const managerUrl = (await axios.get(`${API.GET_BACKUP_MANAGER_URL}?deviceId=${selectedDeviceForDropdown.id}`))
        .data;
      window.open(managerUrl, '_blank');
    } catch (err) {
      dispatch(showToast(getErrorTextForTakeControl(err), 'error'));
    } finally {
      setConnecting(false);
    }
  };

  const reloadDevice = updatedDevice => {
    let updatedDevices = [...devices];
    const index = devices.findIndex(device => device.serverid === updatedDevice.serverid);
    updatedDevices.splice(index, 1, updatedDevice);
    setDevices(updatedDevices);
  };

  const fetchFullDataFromFirestore = async objectIDs => {
    if (objectIDs.length === 0) return [];

    const chunkSize = 30; // Firestore 'in' query limit
    const chunks = []; // Store results here

    // Split objectIDs into chunks of 30
    const requests = objectIDs.reduce((acc, _, i) => {
      if (i % chunkSize === 0) {
        const chunk = objectIDs.slice(i, i + chunkSize);
        const deviceQuery = query(props.firebase.devices(), where('__name__', 'in', chunk));

        acc.push(
          getDocs(deviceQuery).then(snapshots => {
            snapshots.docs.forEach(doc => {
              chunks.push({
                id: doc.id,
                ...doc.data(),
              });
            });
          }),
        );
      }
      return acc;
    }, []);

    // Run up to 10 requests concurrently
    for (let i = 0; i < requests.length; i += 10) {
      await Promise.all(requests.slice(i, i + 10));
    }
    chunks.sort((x, y) => objectIDs.indexOf(x.id) - objectIDs.indexOf(y.id));

    return chunks;
  };

  useEffect(() => {
    // Trigger a refresh whenever the selected region changes
    setTriggerRefresh(true);
  }, [selectedRegion, filterByStatus, tableSearch]);

  useEffect(() => {
    if (triggerRefresh) {
      // Call the fetch function or reset pagination/page to trigger a re-fetch
      tableRef.current && tableRef.current.onQueryChange();
      setTriggerRefresh(false);
    }
  }, [triggerRefresh]);

  const fetchFromAlgolia = useCallback(
    async query => {
      if (manualDevices) {
        const returnData = {
          data: manualDevices,
          page: currentPage,
          totalCount: totalCount,
        };
        setManualDevices(null);
        return returnData;
      }

      const { page, pageSize } = query;

      const regionQuery = {};
      const siteIdQuery = {};

      if (selectedRegion) {
        regionQuery.eq = selectedRegion;
      }
      if (authUser.role !== 'distributor') {
        regionQuery.in = getAllowedRegions(authUser);
      } else {
        siteIdQuery.in = distributorSiteIdsRef.current;
      }

      const buildQuery = filter => (filter?.length ? { in: filter } : {});

      const stateQuery = buildQuery(filterByStatus.state);
      const backupQuery = buildQuery(filterByStatus.backup);
      const warrantyQuery = buildQuery(filterByStatus.warranty);

      const statusBuilderQuery = {
        ...(Object.keys(stateQuery).length > 0 && { deviceState: stateQuery }),
        ...(Object.keys(backupQuery).length > 0 && { backupStatus: backupQuery }),
        ...(Object.keys(warrantyQuery).length > 0 && { warrantyStatus: warrantyQuery }),
      };
      const builderQuery = {
        ...(Object.keys(regionQuery).length > 0 && { region: regionQuery }),
        ...(Object.keys(siteIdQuery).length > 0 && { siteid: siteIdQuery }),
        ...(Object.keys(statusBuilderQuery).length > 0 && statusBuilderQuery),
      };
      const algoliaQueryBuilder = new AlgoliaQueryBuilder(builderQuery);
      const filterQuery = algoliaQueryBuilder.exec();
      try {
        setAlgoliaFilter(filterQuery);
        // Perform search with Algolia
        const result = await client.searchSingleIndex({
          indexName: process.env.REACT_APP_ALOGORIA_INDEX_NAME,
          searchParams: {
            query: tableSearch,
            page,
            hitsPerPage: pageSize,
            facets: ['deviceState', 'warrantyStatus', 'backupStatus'],
            filters: filterQuery,
            typoTolerance: false,
          },
        });

        setFacetResult(result.facets);
        // Extract document IDs from Algolia results
        const objectIDs = result.hits.map(hit => hit.objectID);

        setFetchedAlgoliaDeviceIds(objectIDs);
        // Fetch full data from Firestore using the object IDs
        const devices = await fetchFullDataFromFirestore(objectIDs);

        setCurrentPage(result.page);
        setTotalCount(result.nbHits);
        // Return results to Material Table
        return {
          data: mapDeviceData(devices, failingChecksRef.current, pulseOnlineDevicesRef.current, tunnelsRef.current),
          page: result.page,
          totalCount: result.nbHits,
        };
      } catch (error) {
        return {
          data: [],
          page: 0,
          totalCount: 0,
        };
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [client, selectedRegion], // Dependencies include Algolia index and filter state
  );

  const fetchAllResults = async () => {
    const indexName = process.env.REACT_APP_ALOGORIA_INDEX_NAME;
    const hitsPerPage = 1000; // Max hits per page allowed by Algolia
    let page = 0;
    let allResults = [];

    while (true) {
      const result = await client.searchSingleIndex({
        indexName,
        searchParams: {
          query: tableSearch,
          hitsPerPage,
          page,
          filters: algoliaFilter,
        },
      });
      // Add current page's hits to the array
      allResults = allResults.concat(result.hits);

      // If we've reached the last page, exit the loop
      if (page >= result.nbPages - 1) {
        break;
      }

      // Increment page number to fetch the next page
      page++;
    }

    return allResults.map(hit => hit.objectID);
  };

  const exportCSV = async type => {
    try {
      setShowSpinner(true);
      const objectIDs = await fetchAllResults();

      // Fetch full data from Firestore using the object IDs
      const devices = await fetchFullDataFromFirestore(objectIDs);
      let fileName = '',
        rows = [],
        columns = [];
      if (type === 'device_data') {
        fileName = 'Devices';
        rows = devices.map(row => getDeviceExportRowData(row));
        columns = DEVICE_EXPORT_COLUMNS;
      } else if (type === 'software_assets') {
        fileName = 'Software_Assets';
        columns = SOFTWARE_ASSET_EXPORT_COLUMNS;
        rows = devices.flatMap(row => getSoftwareAssetExportRowData(row));
      }
      const builder = new CsvBuilder(fileName + '.csv');
      builder.setDelimeter(',').setColumns(columns).addRows(rows).exportFile();
    } finally {
      setShowSpinner(false);
    }
  };

  const onSearchChange = searchTerm => {
    setTableSearch(searchTerm);
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useInterval(async () => {
    if (authUser.role !== 'distributor') {
      return;
    }
    const devices = await fetchFullDataFromFirestore(fetchedAlgoliaDeviceIds);
    const updatedDevices = mapDeviceData(
      devices,
      failingChecksRef.current,
      pulseOnlineDevicesRef.current,
      tunnelsRef.current,
    );
    if (updatedDevices?.length) {
      setManualDevices(updatedDevices);
    }
    if (selectedDeviceForDropdown?.id) {
      const updatedDevice = updatedDevices.find(device => selectedDeviceForDropdown.id === device.id);
      if (updatedDevice) setSelectedDeviceForDropdown(updatedDevice);
    }
    if (selectedDevice?.id) {
      const updatedDevice = updatedDevices.find(device => selectedDevice.id === device.id);
      if (updatedDevice) setSelectedDevice(updatedDevice);
    }
    setTriggerRefresh(true);
  }, 5 * 1000);

  return (
    <StyledContainer maxWidth="xl">
      {!loading ? (
        <div>
          <div className={classes.clearFilterView}>
            <Button
              variant="outlined"
              onClick={() => setShowFilter(showFilter => !showFilter)}
              startIcon={<FilterIcon />}
            >
              {t(!showFilter ? 'show_filters' : 'hide_filters')}
            </Button>
            <Button
              variant="outlined"
              onClick={() => setFilterByStatus(initialFilterByStatus)}
              startIcon={<ClearIcon />}
            >
              {t('clear_filters')}
            </Button>
          </div>
          <div className={classes.dashboard}>
            <Card variant="outlined" className={classes.dashboardCard}>
              <CardContent>
                <Typography variant="h5" component="h2">
                  {t('state')}
                </Typography>
                <Typography color="textSecondary" className={classes.dashboardSubtitle}>
                  {t('state_subtitle')}
                </Typography>
                <StatusBar
                  data={{
                    green: facetResult.deviceState?.green ?? 0,
                    orange: facetResult.deviceState?.orange ?? 0,
                    red: facetResult.deviceState?.red ?? 0,
                    grey: facetResult.deviceState?.grey ?? 0,
                  }}
                  filterName="state"
                  filterByStatus={filterByStatus}
                  setFilterByStatus={setFilterByStatus}
                  key="state-status"
                  showFilter={showFilter}
                />
              </CardContent>
            </Card>
            <Card variant="outlined" className={classes.dashboardCard}>
              <CardContent>
                <Typography variant="h5" component="h2">
                  {t('backup')}
                </Typography>
                <Typography color="textSecondary" className={classes.dashboardSubtitle}>
                  {t('backup_subtitle')}
                </Typography>
                <StatusBar
                  data={{
                    healthy: facetResult.backupStatus?.healthy ?? 0,
                    last30_backup: facetResult.backupStatus?.last30_backup ?? 0,
                    no_backup: facetResult.backupStatus?.no_backup ?? 0,
                  }}
                  filterName="backup"
                  filterByStatus={filterByStatus}
                  setFilterByStatus={setFilterByStatus}
                  key="backup-status"
                  showFilter={showFilter}
                />
              </CardContent>
            </Card>
            <Card variant="outlined" className={classes.dashboardCard}>
              <CardContent>
                <Typography variant="h5" component="h2">
                  {t('warranty')}
                </Typography>
                <Typography color="textSecondary" className={classes.dashboardSubtitle}>
                  {t('warranty_subtitle')}
                </Typography>
                <StatusBar
                  data={{
                    inWarranty: facetResult.warrantyStatus?.inWarranty ?? 0,
                    outOfWarranty: facetResult.warrantyStatus?.outOfWarranty ?? 0,
                  }}
                  filterName="warranty"
                  filterByStatus={filterByStatus}
                  setFilterByStatus={setFilterByStatus}
                  key="warranty-status"
                  showFilter={showFilter}
                />
              </CardContent>
            </Card>
          </div>
          <div className={classes.table}>
            <div className={classes.tableFilter}>
              <div className={classes.regionSelect}>
                <Typography>{t('region')}:</Typography>
                <Select
                  labelId="region-select-label"
                  label="Region"
                  id="region-select"
                  name="region"
                  onChange={onSelectChange}
                  value={selectedRegion}
                  required
                  displayEmpty={true}
                  fullWidth
                  disabled={loading}
                >
                  {_.map(regionsWithAll, region => (
                    <MenuItem key={region.code} value={region.code}>
                      {region.name}
                    </MenuItem>
                  ))}
                </Select>
              </div>
              <div className={classes.searchBar}>
                <AppSearchBar onSearch={onSearchChange}></AppSearchBar>
              </div>
            </div>
            <DeviceTable
              tableRef={tableRef}
              columns={columns}
              data={fetchFromAlgolia}
              loading={connecting}
              exportCSV={exportCSV}
            />

            <StyledMenu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
              <MenuItem
                onClick={connectToServer}
                disableRipple
                disabled={
                  isOffline(selectedDeviceForDropdown) || !canConnectToDevice(authUser, selectedDeviceForDropdown)
                }
              >
                <CastIcon />
                &nbsp;
                {t(
                  isOffline(selectedDeviceForDropdown)
                    ? 'offline'
                    : !canConnectToDevice(authUser, selectedDeviceForDropdown)
                    ? 'connect - not enabled'
                    : 'connect',
                )}
              </MenuItem>
              <MenuItem
                onClick={launchBackupManager}
                disabled={!canConnectToDevice(authUser, selectedDeviceForDropdown)}
                disableRipple
              >
                <BackupIcon />
                &nbsp;
                {t(
                  canConnectToDevice(authUser, selectedDeviceForDropdown)
                    ? 'launch_backup_manager'
                    : 'launch_backup_manager - not enabled',
                )}
              </MenuItem>
              <MenuItem
                onClick={connectToTunnel}
                disabled={
                  isOffline(selectedDeviceForDropdown) ||
                  !canConnectoToTunnel(!selectedDeviceForDropdown) ||
                  !selectedDeviceForDropdown.tunnel
                }
                disableRipple
              >
                <SettingsEthernetIcon />
                &nbsp;
                {t(
                  canConnectoToTunnel(selectedDeviceForDropdown)
                    ? selectedDeviceForDropdown?.deviceType === 'MI'
                      ? 'connect_tunnel_mi'
                      : 'connect_tunnel_cirrus_pro'
                    : 'connect_tunnel - not enabled',
                )}
              </MenuItem>
            </StyledMenu>
            <ConnectDialog controlUrl={takeControlUrl} />
          </div>
          {isDeviceInfoModalOpen ? (
            <DeviceInformationDialog
              device={selectedDevice}
              reload={reloadDevice}
              show={isDeviceInfoModalOpen}
              toggler={setIsDeviceInfoModalOpen}
              firebase={props.firebase}
            />
          ) : null}
          {showSpinner ? (
            <div className={classes.spinnerView}>
              <CircularProgress align="center" className={classes.spinnerContent} />
            </div>
          ) : null}
        </div>
      ) : (
        <div style={{ textAlign: 'center' }}>
          <CircularProgress />
        </div>
      )}
    </StyledContainer>
  );
};

export default withFirebase(DevicePage);
