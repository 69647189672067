import React from 'react';

import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

import { useTranslation } from 'react-i18next';

const PREFIX = 'NotFoundPage';

const classes = {
  root: `${PREFIX}-root`
};

const StyledContainer = styled(Container)((
  {
    theme
  }
) => ({
  [`&.${classes.root}`]: {
    marginTop: theme.spacing(3),
  }
}));

const NotFoundPage = () => {

  const { t } = useTranslation();

  return (
    <StyledContainer className={classes.root} maxWidth="sm">
      <Typography align="center" variant="h2">
        {t('page not found')}
      </Typography>
    </StyledContainer>
  );
};

export default NotFoundPage;
