export const setUserSigning = flag => {
  return {
    type: 'SET_USER_SIGNING',
    payload: flag,
  };
};

export const updateUser = userData => {
  return {
    type: 'UPDATE_USER',
    payload: userData,
  };
};
