import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { withFirebase } from '../Firebase';
import { useTranslation } from 'react-i18next';
import LanguageSelect from '../LanguageSelect/LanguageSelect';
import phone from 'phone';
import { stripPhone } from '../../utils/phoneNumber';
import { useDispatch, useSelector } from 'react-redux';
import { showToast } from '../../store/actions/toastAction';
import AdornedButton from '../Button/AdornedButton';
import { updateDoc } from 'firebase/firestore';

const PREFIX = 'AccountIndex';

const classes = {
  divider: `${PREFIX}-divider`,
  sectionTitle: `${PREFIX}-sectionTitle`,
  flex: `${PREFIX}-flex`,
  languageSelect: `${PREFIX}-languageSelect`,
  notificationSettingContent: `${PREFIX}-notificationSettingContent`,
  notificationSettingTopContent: `${PREFIX}-notificationSettingTopContent`,
  notificationSettingSubtitle: `${PREFIX}-notificationSettingSubtitle`,
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${classes.divider}`]: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },

  [`& .${classes.sectionTitle}`]: {
    fontSize: '18px',
    fontWeight: 'bold',
    margin: '10px 0',
  },

  [`& .${classes.flex}`]: {
    display: 'flex',
  },

  [`& .${classes.languageSelect}`]: {
    marginTop: '15px',
  },

  [`& .${classes.notificationSettingContent}`]: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '10px',
  },

  [`& .${classes.notificationSettingTopContent}`]: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: '16px',
    fontWeight: '500',
  },

  [`& .${classes.notificationSettingSubtitle}`]: {
    fontSize: '12px',
  },
}));

const INITIAL_STATE = {
  passwordOne: '',
  passwordTwo: '',
  phoneNumber: '',
};

const Account = ({ show, toggler, firebase }) => {
  const [formData, setFormData] = useState({ ...INITIAL_STATE });
  const [loading, setLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [error, setError] = useState('');
  let [deviceOverdueFlag, setDeviceOverdueFlag] = useState(false);
  let [failedCheckFlag, setFailedCheckFlag] = useState(false);
  const authUser = useSelector(state => state.user);

  useEffect(() => {
    setDeviceOverdueFlag(!authUser.unsubscribedNotifications?.includes('overdue') ?? true);
    setFailedCheckFlag(!authUser.unsubscribedNotifications?.includes('failing_check') ?? true);
  }, [authUser]);

  const onChange = event => {
    event.preventDefault();
    setFormData(prevFormData => ({
      ...prevFormData,
      [event.target.name]: event.target.value,
    }));
  };

  const handleNotificationSettingChange = async event => {
    const { name, checked } = event.target;
    if (name === 'overdue') {
      setDeviceOverdueFlag(checked);
      deviceOverdueFlag = checked;
    } else {
      setFailedCheckFlag(checked);
      failedCheckFlag = checked;
    }
    setLoading(true);
    const unsubscribedNotifications = [!deviceOverdueFlag && 'overdue', !failedCheckFlag && 'failing_check'].filter(
      Boolean,
    );
    try {
      await updateUser(authUser.uid, { unsubscribedNotifications });
    } catch (e) {
      setError(String(e));
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = authUser => async () => {
    const { phoneNumber, language } = formData;
    let params = {};
    if (language) {
      params.language = language;
    }
    if (phoneNumber) {
      params.phoneNumber = phoneNumber;
    }
    setSaveLoading(true);
    try {
      await updateUser(authUser.uid, params);
      if (passwordOne && passwordTwo) {
        await firebase.doPasswordUpdate(passwordOne);
        setFormData({ ...INITIAL_STATE });
        dispatch(showToast(t('password updated, signing out')));
        setTimeout(() => {
          firebase.doSignOut();
        }, 1000);
      }
      toggler(false);
    } catch (e) {
      setError(String(e));
    } finally {
      setSaveLoading(false);
    }
  };

  const updateUser = (userId, object) => {
    if (Object.keys(object).length < 1) {
      return;
    }
    return updateDoc(firebase.user(userId), object);
  };

  const { passwordOne, passwordTwo } = formData;
  const passwordInvalid = passwordOne !== passwordTwo;

  const onPhoneChange = event => {
    event.persist();
    setFormData(prevFormData => ({
      ...prevFormData,
      [event.target.name]: stripPhone(event.target.value),
    }));
  };

  const validatePhoneNumber = event => {
    if (!phone(event.target.value).isValid) {
      setError(t('invalid phone format'));
    } else {
      setError(null);
    }
  };

  return (
    <div>
      <StyledDialog open={show || false} onClose={() => toggler(false)} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{t('my account')}</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            id="email"
            label={t('email')}
            type="email"
            fullWidth
            value={authUser.email}
            disabled
          />
          <TextField
            margin="dense"
            id="phoneNumber"
            label={t('phone number')}
            name="phoneNumber"
            fullWidth
            defaultValue={authUser.phoneNumber}
            onChange={onPhoneChange}
            onBlur={validatePhoneNumber}
          />
          <TextField margin="dense" id="name" label={t('name')} type="text" fullWidth value={authUser.name} disabled />
          <TextField
            margin="dense"
            id="organization"
            label={t('organization')}
            type="text"
            fullWidth
            value={authUser.organization}
            disabled
          />
          <TextField
            margin="dense"
            id="region"
            label={t('region')}
            type="text"
            fullWidth
            value={(authUser.region || '').toUpperCase()}
            disabled
          />
          <div className={classes.languageSelect}>
            <LanguageSelect value={formData.language || authUser.language} onChange={onChange} />
          </div>

          <Divider className={classes.divider} />
          <p className={classes.sectionTitle}>{t('notification_settings')}</p>
          <div>
            <div className={classes.notificationSettingContent}>
              <div className={classes.notificationSettingTopContent}>
                <span>{t('notification_device_overdue_title')}</span>
                <Switch name="overdue" checked={deviceOverdueFlag} onChange={handleNotificationSettingChange} />
              </div>
              <span className={classes.notificationSettingSubtitle}>{t('notification_device_overdue_subtitle')}</span>
            </div>
            <div className={classes.notificationSettingContent}>
              <div className={classes.notificationSettingTopContent}>
                <span>{t('notification_failed_check_title')}</span>
                <Switch name="failing_check" checked={failedCheckFlag} onChange={handleNotificationSettingChange} />
              </div>
              <span className={classes.notificationSettingSubtitle}>{t('notification_failed_check_subtitle')}</span>
            </div>
          </div>
          <Divider className={classes.divider} />
          <p className={classes.sectionTitle}>{t('set new password')}</p>
          <TextField
            value={passwordOne || ''}
            onChange={onChange}
            margin="dense"
            name="passwordOne"
            id="passwordOne"
            label={t('password')}
            type="password"
            fullWidth
          />
          <TextField
            value={passwordTwo || ''}
            onChange={onChange}
            margin="dense"
            name="passwordTwo"
            id="passwordTwo"
            label={t('confirm')}
            type="password"
            fullWidth
          />
          {error && (
            <Typography variant="subtitle2" color="error">
              {error}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <AdornedButton variant="contained" onClick={() => toggler(false)} loading={loading || saveLoading}>
            {t('cancel')}
          </AdornedButton>
          <AdornedButton
            variant="contained"
            color="primary"
            onClick={handleSubmit(authUser)}
            disabled={passwordInvalid || !!(formData.phoneNumber && !phone(formData.phoneNumber).isValid)}
            loading={saveLoading || loading}
          >
            {t('save')}
          </AdornedButton>
        </DialogActions>
      </StyledDialog>
    </div>
  );
};

export default withFirebase(Account);
