import { initializeApp } from 'firebase/app';
import {
  getAuth,
  signInWithEmailAndPassword,
  signOut,
  verifyPasswordResetCode,
  confirmPasswordReset,
  updatePassword,
  connectAuthEmulator,
} from 'firebase/auth';
import { getFirestore, connectFirestoreEmulator, collection, doc, where, getDocs, query } from 'firebase/firestore';
import { getDatabase, connectDatabaseEmulator } from 'firebase/database';
import { getStorage, ref as storageRef, connectStorageEmulator } from 'firebase/storage';
import { chunk, flatten, uniqBy } from 'lodash';

const useEmulator = process.env.REACT_APP_FIREBASE_USE_EMULATOR === 'true';

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
};

class Firebase {
  constructor() {
    const app = initializeApp(config);
    this.auth = getAuth(app);
    this.db = getFirestore(app);
    this.rtdb = getDatabase(app);
    this.storage = getStorage(app);

    if (useEmulator) {
      connectFirestoreEmulator(this.db, 'localhost', parseInt(process.env.REACT_APP_EMULATOR_FIREBASE_DOMAIN));
      connectAuthEmulator(this.auth, process.env.REACT_APP_EMULATOR_AUTH_DOMAIN);
      connectDatabaseEmulator(this.rtdb, 'localhost', parseInt(process.env.REACT_APP_EMULATOR_DATABASE_DOMAIN));
      connectStorageEmulator(this.storage, 'localhost', parseInt(process.env.REACT_APP_EMULATOR_STORAGE_DOMAIN));
    }
  }

  doSignInWithEmailAndPassword = (email, password) => signInWithEmailAndPassword(this.auth, email, password);

  doSignOut = () => signOut(this.auth);

  doPasswordCodeVerification = code => verifyPasswordResetCode(this.auth, code);

  doPasswordReset = (code, password) => confirmPasswordReset(this.auth, code, password);

  doPasswordUpdate = password => updatePassword(this.auth.currentUser, password);

  // Firebase Firestore database - users
  user = uid => doc(this.db, 'Users', uid);
  users = () => collection(this.db, 'Users');

  // Firebase Firestore database - users
  contact = uid => doc(this.db, 'Contacts', uid);
  contacts = () => collection(this.db, 'Contacts');

  // Firebase Firestore database - twoFactor
  twoFactor = token => query(collection(this.db, 'TwoFactorCodes'), where('token', '==', token));

  // Firebase Firestore database - devices
  device = uid => doc(this.db, 'Devices', uid);
  devices = () => collection(this.db, 'Devices');

  // Firebase Firestore database - monthly reports
  report = uid => doc(this.db, 'MonthlyReports', uid);
  reports = () => collection(this.db, 'MonthlyReports');

  billingReports = () => collection(this.db, 'BillingReports');
  releaseNotes = () => collection(this.db, 'ReleaseNotes');

  // Firebase Firestore database - sites
  site = uid => doc(this.db, 'Sites', uid);
  sites = () => collection(this.db, 'Sites');

  // Firebase Firestore database - clients
  clients = () => collection(this.db, 'Clients');

  // Firebase Firestore database - organizations
  organization = uid => doc(this.db, 'Organizations', uid);
  organizations = () => collection(this.db, 'Organizations');

  // Firebase Firestore database - messaging
  message = uid => doc(this.db, 'Messages', uid);
  messages = () => collection(this.db, 'Messages');

  // Firebase Firestore database - config
  config = uid => doc(this.db, 'Config', uid);
  configs = () => collection(this.db, 'Config');

  // Firebase Firestore database - failing checks
  failingChecks = () => collection(this.db, 'FailingChecks');

  // Get storage ref
  storageRef = path => storageRef(this.storage, path);

  // Like where('field', 'in', array) but not limited to 10 elements
  whereIn = async (qs, field, array) => {
    const chunks = chunk(array, 10);
    const docs = await Promise.all(
      chunks.map(async chunk => (await getDocs(query(qs, where(field, 'in', chunk)))).docs),
    );
    return uniqBy(flatten(docs), 'id');
  };
}

export default Firebase;
