import React, { useState } from 'react';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Autocomplete from '@mui/material/Autocomplete';
import { useTranslation } from 'react-i18next';
import AdornedButton from '../../Button/AdornedButton';

const AddSiteToDistributor = ({ addSite, sites, distributor, onClose }) => {
  const { t } = useTranslation();
  const [site, setSite] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleSiteAdd = (e, site) => {
    e.preventDefault();
    setLoading(true);
    addSite(distributor, site).finally(() => {
      setLoading(false);
      onClose();
    });
  };

  return (
    <Dialog open={!!distributor} onClose={onClose}>
      <form onSubmit={e => handleSiteAdd(e, site)}>
        <DialogTitle>
          {t('distributor')} - {distributor.email || ''} - {distributor.name || ''} - {t('add site')}
        </DialogTitle>
        <DialogContent>
          <Autocomplete
            id="sites-list"
            options={sites}
            getOptionLabel={option =>
              `${option.region?.toUpperCase() || ''} - ${option.clientName || ''} - ${option.name}`
            }
            onChange={(event, newValue) => {
              setSite(newValue);
            }}
            renderInput={params => <TextField {...params} label={t('sites')} variant="standard" />}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={onClose}>
            {t('cancel')}
          </Button>
          <AdornedButton type="submit" variant="contained" color="primary" disabled={loading} loading={loading}>
            {t('add')}
          </AdornedButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};
export default AddSiteToDistributor;
