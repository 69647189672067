import React, { useState } from 'react';

import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Container from '@mui/material/Container';

import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { showToast } from '../../store/actions/toastAction';
import AdornedButton from '../Button/AdornedButton';

const PREFIX = 'index';

const classes = {
  paper: `${PREFIX}-paper`,
  avatar: `${PREFIX}-avatar`,
  form: `${PREFIX}-form`,
  submit: `${PREFIX}-submit`
};

const StyledContainer = styled(Container)((
  {
    theme
  }
) => ({
  [`& .${classes.paper}`]: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  [`& .${classes.avatar}`]: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },

  [`& .${classes.form}`]: {
    width: '100%',
    marginTop: theme.spacing(1),
  },

  [`& .${classes.submit}`]: {
    margin: theme.spacing(3, 0, 2),
  }
}));

const PasswordChangePage = props => {

  const { t } = useTranslation();
  const params = new URLSearchParams(props.location.search);
  const code = params.get('oobCode');
  const mode = params.get('mode');
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({ ...INITIAL_STATE });

  const onSubmit = async event => {
    event.preventDefault();
    try {
      if (mode && code) {
        if (mode === 'resetPassword') {
          const { passwordOne } = formData;

          setLoading(true);
          await props.firebase.doPasswordCodeVerification(code);
          await props.firebase.doPasswordReset(code, passwordOne);

          setFormData({ ...INITIAL_STATE });
          dispatch(showToast(t('password_change_success')));
          setLoading(false);
          await props.firebase.doSignOut();
          props.history.push(ROUTES.SIGN_IN);
        } else {
          dispatch(showToast('Wrong mode specified.', 'error'));
        }
      } else {
        dispatch(showToast('The mode and code are mandatory.', 'error'));
      }
    } catch (e) {
      setLoading(false);
      dispatch(showToast(String(e), 'error'));
    }
  };

  const onChange = event => {
    event.persist();
    setFormData(prevFormData => ({
      ...prevFormData,
      [event.target.name]: event.target.value,
    }));
  };

  const { passwordOne, passwordTwo } = formData;
  const isInvalid = passwordOne !== passwordTwo || passwordOne === '';

  return (
    <StyledContainer component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          {t('change password')}
        </Typography>
      </div>
      <form className={classes.form} onSubmit={onSubmit}>
        <TextField
          name="passwordOne"
          margin="normal"
          required
          fullWidth
          value={passwordOne || ''}
          onChange={onChange}
          type="password"
          label={t('new password')}
          variant="outlined"
        />
        <TextField
          name="passwordTwo"
          margin="normal"
          required
          fullWidth
          value={passwordTwo || ''}
          onChange={onChange}
          type="password"
          label={t('confirm new password')}
          variant="outlined"
        />
        <AdornedButton
          disabled={isInvalid || loading}
          loading={loading}
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
        >
          {t('reset password')}
        </AdornedButton>
      </form>
    </StyledContainer>
  );
};

const INITIAL_STATE = {
  passwordOne: '',
  passwordTwo: '',
};

export default withFirebase(PasswordChangePage);
