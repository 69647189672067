import Button from '@mui/material/Button';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@mui/material/CircularProgress';
import { useDispatch, useSelector } from 'react-redux';
import { getErrorTextForTakeControl, getTakeControlURL, isOffline } from '../../utils/common';
import { showToast } from '../../store/actions/toastAction';
import ConnectDialog from '../Connect/connect-dialog';

const ConnectButton = props => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const authUser = useSelector(state => state.user);
  const [connecting, setConnecting] = useState(false);
  const [takeControlUrl, setTakeControlUrl] = useState(null);

  const onConnect = async authUser => {
    setConnecting(true);

    try {
      setTakeControlUrl(await getTakeControlURL(authUser, props.server, props.type));
    } catch (err) {
      dispatch(showToast(getErrorTextForTakeControl(err), 'error'));
    } finally {
      setConnecting(false);
    }
  };

  return (
    <>
      {connecting ? (
        <CircularProgress />
      ) : (
        <>
          <ConnectDialog controlUrl={takeControlUrl} />
          <Button
            onClick={() => onConnect(authUser)}
            disabled={isOffline(props.server)}
            variant="contained"
            color="primary"
          >
            {t(isOffline(props.server) ? 'offline' : 'connect')}
          </Button>
        </>
      )}
    </>
  );
};
export default ConnectButton;
