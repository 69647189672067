import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { showToast } from '../../store/actions/toastAction';
import AdornedButton from './AdornedButton';
import { START_TUNNEL } from '../../constants/api';
import { CircularProgress } from '@mui/material';

const ConnectTunnelButton = props => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onConnect = async () => {
    try {
      setLoading(true);
      const res = await axios.post(`${START_TUNNEL}`, {
        deviceId: props.server.id,
      });
      // Set 5 seconds delay before opening domain as ngrok domain may not be available immediately
      setTimeout(() => {
        window.open(`https://${res.data.url}`, '_blank');
        setLoading(false);
      }, 5000);
    } catch (e) {
      dispatch(showToast(`${e}`, 'error'));
      setLoading(false);
    }
  };

  return loading ? (
    <CircularProgress />
  ) : (
    <AdornedButton
      onClick={onConnect}
      disabled={!props.server.tunnel}
      loading={loading}
      variant="contained"
      color="primary"
    >
      {props.server.deviceType === 'MI' ? t('connect_tunnel_mi') : t('connect_tunnel_cirrus_pro')}
    </AdornedButton>
  );
};
export default ConnectTunnelButton;
