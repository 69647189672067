import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import _ from 'lodash';

import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Card from '@mui/material/Card';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { withFirebase } from '../Firebase';
import LedIndicator from '../Common/LedIndicator';
import ConnectButton from '../Button/ConnectButton';
import { isDeviceOnline, isDistributorAccessEnabled, replaceError } from '../../utils/common';
import { showToast } from '../../store/actions/toastAction';
import TrainingSessionBooking from './training-session-booking';
import ToggleDistributorAccessDialog from './toggle-distributor-access-dialog';
import DistributorAccessButton from './distributor-access-button';
import { displayExpiryInDays, getDaysRemaining, getFormatDate, parseDate } from '../Device/const';
import { getDoc, getDocs, query, updateDoc, where } from 'firebase/firestore';
import ConnectTunnelButton from '../Button/ConnectTunnelButton';
import { GET_ACTIVE_TUNNELS } from '../../constants/api';

const PREFIX = 'index';

const classes = {
  paper: `${PREFIX}-paper`,
  serverName: `${PREFIX}-serverName`,
  even: `${PREFIX}-even`,
  flexFull: `${PREFIX}-flexFull`,
  flexContainer: `${PREFIX}-flexContainer`,
  infoLeftContainer: `${PREFIX}-infoLeftContainer`,
  buttonItem: `${PREFIX}-buttonItem`,
  loading: `${PREFIX}-loading`,
  failingChecksBanner: `${PREFIX}-failingChecksBanner`,
  failincChecksArrow: `${PREFIX}-failincChecksArrow`,
  failingChecksArrowOpen: `${PREFIX}-failingChecksArrowOpen`,
  failingChecksContainer: `${PREFIX}-failingChecksContainer`,
  failingChecksText: `${PREFIX}-failingChecksText`,
};

const StyledContainer = styled(Container)(({ theme }) => ({
  [`& .${classes.paper}`]: {
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1),
  },

  [`& .${classes.serverName}`]: {
    marginBottom: theme.spacing(2),
  },

  [`& .${classes.even}`]: {
    backgroundColor: '#f5f5f5',
  },

  [`& .${classes.flexFull}`]: {
    flex: 1,
  },

  [`& .${classes.flexContainer}`]: {
    display: 'flex',
  },

  [`& .${classes.infoLeftContainer}`]: {
    width: '150px',
  },

  [`& .${classes.buttonItem}`]: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
  [`& .${classes.buttonItem} button`]: {
    width: '260px',
  },

  [`& .${classes.loading}`]: {
    textAlign: 'center',
    padding: 30,
  },

  [`& .${classes.failingChecksBanner}`]: {
    display: 'flex',
    alignItems: 'center',
  },

  [`& .${classes.failincChecksArrow}`]: {
    transition: 'transform 0.2s',
    cursor: 'pointer',
  },

  [`& .${classes.failingChecksArrowOpen}`]: {
    transform: 'rotate(180deg)',
  },

  [`& .${classes.failingChecksContainer}`]: {
    padding: '5px',
    marginBottom: '5px',
    fontSize: '8px',
  },

  [`& .${classes.failingChecksText}`]: {
    fontSize: '12px',
  },
}));

const ConnectPage = props => {
  const { t } = useTranslation();
  const currentUser = useSelector(state => state.user);

  const [loading, setLoading] = useState(true);
  const [servers, setServers] = useState({});
  const [failingChecks, setFailingChecks] = useState({});
  const [expandedChecks, setExpandedChecks] = useState([]);
  const [togglingDistributorAccessFor, setTogglingDistributorAccessFor] = useState(null);
  const dispatch = useDispatch();

  const getActiveTunnels = async () => {
    try {
      const res = await axios.get(GET_ACTIVE_TUNNELS);
      return res.data;
    } catch (e) {
      console.error(`Error occurred while fetching active tunnels. Error: ${e}`);
    }
  };

  const fetchData = async () => {
    try {
      const { firebase } = props;

      // Retrieve sites that the current user is an administrator of
      const sitesQuery = query(firebase.sites(), where('administrators', 'array-contains', currentUser.uid));

      const querySnapshot = await getDocs(sitesQuery);
      let sites = querySnapshot.docs.map(doc => doc.data());

      // Retrieve sites that the current user is a distributor of
      if (currentUser.role === 'distributor') {
        const currentUserRef = await getDoc(firebase.user(currentUser.uid));
        const distributorSitesSnapshot = await getDocs(
          query(firebase.sites(), where('distributors', 'array-contains', currentUserRef.ref)),
        );
        const distributorSites = distributorSitesSnapshot.docs.map(doc => doc.data());
        sites = _.uniqBy([...sites, ...distributorSites], 'siteid');
      }

      // Filter sites that have the 'connect' feature setting
      const filteredSites = _.filter(sites, site => _.includes(site.featureSetting, 'connect'));
      if (_.isEmpty(filteredSites)) {
        return;
      }

      // Extract the site IDs and site-region IDs from the filtered sites
      const siteIds = _.map(filteredSites, 'siteid');
      const siteRegIds = _.map(filteredSites, site => `${site.region}.${site.siteid}`);
      // IDs of the sites that the user is a distributor of
      const distributorSiteIds = _.map(
        _.filter(sites, site => !_.includes(site.administrators, currentUser.uid)),
        'siteid',
      );
      // Extract the distributors for each site
      const siteDistributors = _.reduce(
        filteredSites,
        (acc, site) => {
          acc[site.siteid] = site.distributors;
          return acc;
        },
        {},
      );

      const tunnels = await getActiveTunnels();
      // Retrieve failing checks associated with the filtered sites
      const failingChecksSnapshot = await getDocs(query(firebase.failingChecks(), where('siteid', 'in', siteRegIds)));
      setFailingChecks(
        _.reduce(
          failingChecksSnapshot.docs,
          (checks, data) => {
            checks[data.serverid] = _.get(checks, data.serverid, []).concat(data);
            return checks;
          },
          {},
        ),
      );
      // Retrieve devices associated with the filtered sites
      const devicesSnapshot = await getDocs(query(firebase.devices(), where('siteid', 'in', siteIds)));
      setServers(
        devicesSnapshot.docs
          .filter(doc => {
            // Distributors can connect only if distributor access is enabled
            return !(
              currentUser.role === 'distributor' &&
              _.includes(distributorSiteIds, doc.data().siteid) &&
              !isDistributorAccessEnabled(doc.data())
            );
          })
          .map(doc => {
            const server = doc.data();
            const gspExpiry = parseDate(server.gsp_expiry);
            const tunnelData = tunnels.find(tunnel => tunnel.labels?.rmmid === server.serverid);
            return {
              ...server,
              software: typeof server.software === 'string' ? server.software : '',
              deviceState: isDeviceOnline(server) ? 'green' : 'grey',
              distributors: siteDistributors[server.siteid],
              gspExpiry: getFormatDate(gspExpiry),
              gspExpiryInDays: getDaysRemaining(gspExpiry),
              tunnel: tunnelData,
              deviceType: tunnelData?.labels?.deviceType ?? 'CirrusPro',
              id: doc.id,
            };
          }),
      );
    } catch (error) {
      // Handle errors and show an error toast
      dispatch(showToast(`${error}`, 'error'));
    } finally {
      // Set the loading state to false
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getLocationString = server => {
    const city = replaceError(server.city);
    const country = replaceError(server.country);
    if (!city && !country) {
      return 'N/A';
    }
    return `${city}, ${country}`;
  };

  const toggleFailingCheck = serverId => () => {
    const isExpanded = expandedChecks.indexOf(serverId);
    if (isExpanded >= 0) {
      setExpandedChecks(_.filter(expandedChecks, server => serverId !== server));
    } else {
      setExpandedChecks([serverId, ...expandedChecks]);
    }
  };

  const toggleDistributorAccess = async device => {
    let enabledAt;
    const deviceDoc = await getDoc(props.firebase.device(device.id));
    if (isDistributorAccessEnabled(deviceDoc.data())) {
      enabledAt = null;
      await updateDoc(props.firebase.device(device.id), {
        distributorAccessEnabledAt: null,
      });
    } else {
      // Set the distributor access granted at time to the current UTC unix timestamp
      enabledAt = new Date().getTime();
      await updateDoc(props.firebase.device(device.id), {
        distributorAccessEnabledAt: enabledAt,
      });
    }
    setServers(servers.map(d => (d.id === device.id ? { ...d, distributorAccessEnabledAt: enabledAt } : d)));
  };

  const canToggleDistributorAccess = server => {
    return !!server.distributors?.length && currentUser.role !== 'distributor';
  };

  const handleDistributorAccessDialogClose = () => {
    setTogglingDistributorAccessFor(null);
  };

  return (
    <StyledContainer maxWidth="md">
      <TrainingSessionBooking />
      {loading ? (
        <div className={classes.loading}>
          <CircularProgress align="center" />
        </div>
      ) : servers.length > 0 ? (
        _.map(servers, (server, i) => (
          <Paper elevation={2} className={`${classes.paper} ${i % 2 ? classes.even : ''}`} key={server.name}>
            <Grid container spacing={2}>
              <Grid item className={classes.flexFull}>
                <Typography className={classes.serverName} variant="h6">
                  <LedIndicator classes={server.deviceState} /> {server.name}
                </Typography>
                <div className={classes.flexContainer}>
                  <Typography variant="subtitle2" className={classes.infoLeftContainer}>
                    {t('description')}:
                  </Typography>
                  <Typography variant="subtitle2">{server.description}</Typography>
                </div>
                <div className={classes.flexContainer}>
                  <Typography variant="subtitle2" className={classes.infoLeftContainer}>
                    {t('site')}:
                  </Typography>
                  <Typography variant="subtitle2">
                    {server.region.toUpperCase()} - {server.clientName} - {server.siteName}
                  </Typography>
                </div>
                <div className={classes.flexContainer}>
                  <Typography variant="subtitle2" className={classes.infoLeftContainer}>
                    {t('location')}:
                  </Typography>
                  <Typography variant="subtitle2">{getLocationString(server)}</Typography>
                </div>
                <div className={classes.flexContainer}>
                  <Typography variant="subtitle2" className={classes.infoLeftContainer}>
                    {t('software')}:
                  </Typography>
                  <Typography variant="subtitle2">{replaceError(server.software) || 'N/A'}</Typography>
                </div>
                <div className={classes.flexContainer}>
                  <Typography variant="subtitle2" className={classes.infoLeftContainer}>
                    {t('gsp_expiry')}:
                  </Typography>
                  <Typography variant="subtitle2">
                    {server.gsp_expiry} {displayExpiryInDays(server.gspExpiryInDays)}
                  </Typography>
                </div>
              </Grid>
              <Grid item className={classes.buttonItem}>
                <ConnectButton server={server} type="control" />
                <ConnectTunnelButton server={server} />
                {canToggleDistributorAccess(server) && (
                  <DistributorAccessButton
                    server={server}
                    setTogglingDistributorAccessFor={setTogglingDistributorAccessFor}
                    classes={classes}
                  />
                )}
              </Grid>
            </Grid>
            {failingChecks[server.serverid] && (
              <Typography variant="subtitle2" color="error" className={classes.failingChecksBanner}>
                {t('there are failing checks', { noOfChecks: failingChecks[server.serverid].length })}
                <ExpandMoreIcon
                  className={`${classes.failincChecksArrow} ${
                    expandedChecks.includes(server.serverid) ? classes.failingChecksArrowOpen : ''
                  }`}
                  onClick={toggleFailingCheck(server.serverid)}
                />
              </Typography>
            )}
            {expandedChecks.includes(server.serverid) &&
              _.map(failingChecks[server.serverid], check => (
                <Card className={classes.failingChecksContainer} key={`${server.serverid}${check.checkid}`}>
                  <Typography className={classes.failingChecksText}>{check.formatted_output}</Typography>
                  <Typography className={classes.failingChecksText}>{check.description}</Typography>
                </Card>
              ))}
          </Paper>
        ))
      ) : (
        <Typography align="center" variant="subtitle2">
          {t('no servers available')}
        </Typography>
      )}
      {!!togglingDistributorAccessFor && (
        <ToggleDistributorAccessDialog
          onToggle={toggleDistributorAccess}
          onClose={handleDistributorAccessDialogClose}
          device={togglingDistributorAccessFor}
        />
      )}
    </StyledContainer>
  );
};

export default withFirebase(ConnectPage);
