import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import _ from 'lodash';

import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import { withFirebase } from '../Firebase';
import TableIcons from '../../constants/tableIcons';
import UpdateUserDialog from './dialogs/UpdateUser';
import AddUserDialog from './dialogs/AddUser';
import { useTranslation } from 'react-i18next';
import MaterialTable from '../MaterialTable/MaterialTable';
import { getSitesFromDatabase, REGION_LOOKUP } from '../../utils/common';
import { useDispatch, useSelector } from 'react-redux';
import { showToast } from '../../store/actions/toastAction';
import { getDocs } from 'firebase/firestore';

const PREFIX = 'index';

const classes = {
  link: `${PREFIX}-link`,
  table: `${PREFIX}-table`,
  select: `${PREFIX}-select`
};

const StyledContainer = styled(Container)((
  {
    theme
  }
) => ({
  [`& .${classes.link}`]: {
    cursor: 'pointer',
    fontWeight: 'bold',
  },

  [`& .${classes.table}`]: {
    margin: theme.spacing(2, 0),
  },

  [`& .${classes.select}`]: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
  }
}));

const UsersTable = ({ data, columns }) => {
  const { t } = useTranslation();

  return (
    <MaterialTable
      icons={TableIcons}
      title={t('users')}
      columns={columns}
      data={data}
      options={{
        filtering: true,
      }}
    />
  );
};

const UsersPage = props => {

  const { t } = useTranslation();
  const [users, setUsers] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [organizations, setOrganizations] = useState([]);
  const [sites, setSites] = useState([]);
  const authUser = useSelector(state => state.user);
  const dispatch = useDispatch();
  const [columns] = useState([
    {
      title: t('email'),
      field: 'email',
      render: rowData => (
        <Link className={classes.link} onClick={() => userClickedRow(rowData)}>
          {rowData.email || ''}
        </Link>
      ),
    },
    {
      title: t('contact name'),
      field: 'name',
    },
    {
      title: t('role'),
      field: 'role',
      lookup: {
        user: t('user'),
        admin: t('admin'),
        super_admin: t('super_admin'),
        system_admin: t('system_admin'),
      },
      render: rowData => {
        return t(rowData.role ?? 'user');
      },
    },
    {
      title: t('organization'),
      field: 'organization_name',
    },
    {
      title: t('region'),
      field: 'region',
      lookup: REGION_LOOKUP,
      render: rowData => rowData.region?.toUpperCase(),
    },
  ]);
  const [showAddUser, setShowAddUser] = useState(false);
  const [showUpdateUser, setShowUpdateUser] = useState(false);
  const [updateUser, setUpdateUser] = useState(null);

  const showCantEditWarning = () => {
    dispatch(showToast(t('cant_edit_high_user'), 'error'));
  };

  const userClickedRow = userData => {
    if (userData.email === authUser.email) {
      setUpdateUser(userData);
      return;
    }
    if (authUser.role === 'super_admin' && (userData.role === 'super_admin' || userData.role === 'system_admin')) {
      showCantEditWarning();
      return;
    }
    if (authUser.role === 'admin' && userData.role !== 'user') {
      showCantEditWarning();
      return;
    }
    setUpdateUser(userData);
  };

  const getUsers = async newOrganizations => {
    const userSnapshot = await getDocs(props.firebase.users());
    const newUsers = userSnapshot.docs.map(doc => {
      const user = doc.data();
      return {
        ...user,
        uid: doc.id,
        id: doc.id,
        email: user.email,
        region: user.region,
        language: user.language,
        organization_id: user.organization?.id ?? '-',
        organization_name:
          _.find(newOrganizations ?? organizations, organization => organization.uid === user.organization?.id)?.name ||
          '',
        role: user.role ?? 'user',
      };
    });
    setUsers(newUsers);
  };

  useEffect(() => {
    setShowUpdateUser(true);
  }, [updateUser]);

  async function fetchData() {
    try {
      const organizationSnapshot = await getDocs(props.firebase.organizations());
      const newOrganizations = organizationSnapshot.docs.map(doc => {
        const organizationData = doc.data();
        return {
          uid: doc.id,
          id: doc.id,
          ...organizationData,
        };
      });
      setOrganizations(newOrganizations);

      await getUsers(newOrganizations);

      setSites(await getSitesFromDatabase(props.firebase, authUser));
      setError(null);
      setLoading(false);
    } catch (e) {
      setError(`${e}`);
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <StyledContainer maxWidth="md">
      {!loading ? (
        <div>
          <Button variant="contained" color="primary" onClick={() => setShowAddUser(true)}>
            {t('new user')}
          </Button>
          <AddUserDialog
            reload={fetchData}
            firebase={props.firebase}
            organizations={organizations}
            sites={sites}
            show={showAddUser}
            toggler={setShowAddUser}
          />
          {updateUser ? (
            <UpdateUserDialog
              reload={fetchData}
              setUser={setUpdateUser}
              user={updateUser}
              firebase={props.firebase}
              organizations={organizations}
              sites={sites}
              show={showUpdateUser}
              toggler={setShowUpdateUser}
            />
          ) : null}
          <div className={classes.table}>
            <UsersTable columns={columns} data={users} />
          </div>
          {error ? (
            <Typography variant="subtitle2" color="error">
              {error}
            </Typography>
          ) : null}
        </div>
      ) : (
        <div style={{ textAlign: 'center' }}>
          <CircularProgress />
        </div>
      )}
    </StyledContainer>
  );
};

export default withFirebase(UsersPage);
