import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import AdornedButton from '../Button/AdornedButton';
import { isDistributorAccessEnabled } from '../../utils/common';

const ToggleDistributorAccessDialog = ({ device, onToggle, onClose }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const isEnabling = !isDistributorAccessEnabled(device);

  const handleToggle = () => {
    setLoading(true);
    onToggle(device)
      .then(() => {
        onClose();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Dialog open={true} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>{isEnabling ? t('enable distributor access') : t('disable distributor access')}</DialogTitle>
      <DialogContent>
        <Typography>
          {isEnabling
            ? t('enable distributor access text', { device: device.name })
            : t('disable distributor access text', { device: device.name })}
        </Typography>
      </DialogContent>
      <DialogActions>
        <AdornedButton variant="contained" onClick={onClose}>
          {t('close')}
        </AdornedButton>
        <AdornedButton variant="contained" color="primary" onClick={handleToggle} disabled={loading} loading={loading}>
          {isEnabling ? t('enable') : t('disable')}
        </AdornedButton>
      </DialogActions>
    </Dialog>
  );
};

export default ToggleDistributorAccessDialog;
