import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import _ from 'lodash';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { useTranslation } from 'react-i18next';
import { showToast } from '../../../store/actions/toastAction';
import { useDispatch } from 'react-redux';
import AdornedButton from '../../Button/AdornedButton';
import { getDocs, query, updateDoc, where } from 'firebase/firestore';
import { arrayRemove, deleteDoc, deleteField } from '@firebase/firestore';

const PREFIX = 'EditOrganization';

const classes = {
  name: `${PREFIX}-name`,
  adminLine: `${PREFIX}-adminLine`,
  adminRemove: `${PREFIX}-adminRemove`,
  adminBlock: `${PREFIX}-adminBlock`,
  select: `${PREFIX}-select`,
  divider: `${PREFIX}-divider`,
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${classes.name}`]: {
    marginBottom: theme.spacing(1),
  },

  [`& .${classes.adminLine}`]: {
    marginRight: 20,
  },

  [`& .${classes.adminRemove}`]: {
    marginLeft: 'auto',
    verticalAlign: 'top',
  },

  [`& .${classes.adminBlock}`]: {
    display: 'flex',
    marginBottom: 10,
    borderBottom: '1px solid #e0e0e0',
  },

  [`& .${classes.select}`]: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
  },

  [`& .${classes.divider}`]: {
    margin: theme.spacing(1, 0),
  },
}));

const EditOrganization = ({ organization, setOrganization, firebase, show, reload, toggler }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [showRemoveMember, setShowRemoveMember] = useState(false);
  const [showDeleteOrganization, setShowDeleteOrganization] = useState(false);

  const onChange = event => {
    event.persist();
    setOrganization(prevOrganization => ({
      ...prevOrganization,
      [event.target.name]: event.target.name === 'disabled' ? event.target.checked : event.target.value,
    }));
  };

  const closeRemoveUser = () => {
    setSelectedUser(null);
    setShowRemoveMember(false);
  };

  const deleteOrganization = async () => {
    setLoading(true);
    setShowDeleteOrganization(false);

    await deleteDoc(firebase.organization(organization.uid));
    await reload();
    setOrganization(null);
  };

  const updateOrganization = () => {
    setLoading(true);
    firebase
      .organization(organization.uid)
      .update({
        name: organization.name,
        region: organization.region,
        disabled: organization.disabled || false,
      })
      .then(async () => {
        await reload();
        setLoading(false);
        dispatch(showToast('Organization updated.'));
        toggler(false);
      })
      .catch(e => {
        setLoading(false);
        dispatch(showToast(`${e}`, 'error'));
      });
  };

  const removeUser = async () => {
    setLoading(true);
    setShowRemoveMember(false);
    try {
      await updateDoc(firebase.user(selectedUser.uid), {
        organization: deleteField(),
      });
      setOrganization(prevOrganization => ({
        ...prevOrganization,
        members: _.filter(organization.members, member => member.uid !== selectedUser.uid),
      }));
      const siteSnapshot = await getDocs(
        query(firebase.site(), where('administrators', 'array-contains', selectedUser.uid)),
      );
      siteSnapshot.docs.forEach(doc => {
        updateDoc(firebase.site(doc.id), {
          administrators: arrayRemove(selectedUser.uid),
        });
      });
      setLoading(false);
      setSelectedUser(null);
    } catch {
      setLoading(false);
      setSelectedUser(null);
      setShowRemoveMember(false);
    }
  };

  useEffect(() => {
    return setShowRemoveMember(true);
  }, [selectedUser]);

  const invalid = organization.members.length > 0;

  return (
    <div>
      <StyledDialog
        open={show}
        onClose={() => {
          toggler(false);
        }}
      >
        <DialogTitle>{organization.name || ''}</DialogTitle>
        <DialogContent>
          <div className={classes.name}>
            <TextField
              margin="dense"
              id="name"
              label={t('name')}
              type="name"
              name="name"
              fullWidth
              value={organization.name || ''}
              onChange={onChange}
              autoFocus
              disabled={loading}
            />
          </div>
          <Divider className={classes.divider} />
          {_.map(organization.members, member => (
            <div className={classes.adminBlock} key={member.uid}>
              <div className={classes.adminLine}>
                <Typography variant="subtitle2">
                  {(member.name || '-') + ` (${(member.region || '-').toUpperCase()})`}
                </Typography>
                <Typography variant="caption">{member.email || '-'}</Typography>
              </div>
              <div className={classes.adminRemove}>
                <IconButton onClick={() => setSelectedUser(member)} size="small" disabled={loading}>
                  <HighlightOffIcon color="error" />
                </IconButton>
              </div>
            </div>
          ))}
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setShowDeleteOrganization(true)}
            disabled={invalid || loading}
          >
            {t('delete')}
          </Button>
          <Button variant="contained" onClick={() => toggler()} disabled={loading}>
            {t('cancel')}
          </Button>
          <AdornedButton
            variant="contained"
            color="primary"
            onClick={() => updateOrganization()}
            disabled={loading}
            loading={loading}
          >
            {t('save')}
          </AdornedButton>
        </DialogActions>
      </StyledDialog>

      {selectedUser ? (
        <Dialog open={showRemoveMember} onClose={() => closeRemoveUser()}>
          <DialogContent>
            {t('remove user from organization', {
              user: selectedUser ? `${selectedUser.name || '-'} (${selectedUser.email || '-'})` : '',
              organization: organization.name,
            })}
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="secondary" onClick={() => closeRemoveUser()} disabled={loading}>
              {t('no')}
            </Button>
            <Button variant="contained" color="primary" onClick={() => removeUser()} disabled={loading}>
              {t('yes')}
            </Button>
          </DialogActions>
        </Dialog>
      ) : null}

      <Dialog open={showDeleteOrganization} onClose={() => setShowDeleteOrganization()}>
        <DialogContent>
          {t('delete organization confirmation', {
            orgName: organization.name,
          })}
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="secondary" onClick={() => setShowDeleteOrganization()} disabled={loading}>
            {t('no')}
          </Button>
          <Button variant="contained" color="primary" onClick={() => deleteOrganization()} disabled={loading}>
            {t('yes')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default EditOrganization;
