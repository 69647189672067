import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { useIdleTimer } from 'react-idle-timer';
import Navigation from '../Navigation';
import SignInPage from '../SignIn';
import ConnectPage from '../Connect';
import ForgotPasswordPage from '../ForgotPassword';
import PasswordChangePage from '../PasswordChange';
import NotFoundPage from '../404';
import UsersPage from '../Users';
import SitesPage from '../Sites';
import ReportsPage from '../Report';
import TestPage from '../Test';
import MessagingPage from '../Messaging';
import OrganizationsPage from '../Organizations';
import DevicePage from '../Device';
import DistributorsPage from '../Distributors';
import * as ROUTES from '../../constants/routes';
import TwoFactorPage from '../TwoFactor';
import ProtectedRoute from '../Guards/ProtectedRoute';
import { withAuthentication } from '../Session';
import { useDispatch, useSelector } from 'react-redux';
import { isAuthUserAdmin, timeOutInMilliSeconds } from '../../utils/common';
import { Alert, Snackbar } from '@mui/material';
import { hideToast } from '../../store/actions/toastAction';

import axios from 'axios';
import BillingReportPage from '../Billing';
import ContactsPage from '../Contacts';
import ReleaseNotesPage from '../ReleaseNotes';

axios.defaults.baseURL = process.env.REACT_APP_FUNCTIONS_URL;
axios.defaults.headers.post['Content-Type'] = 'application/json';

const App = ({ firebase }) => {
  const [isAdmin, setIsAdmin] = useState(false);
  const dispatch = useDispatch();

  const authUser = useSelector(state => state.user);
  const toast = useSelector(state => state.toast);

  axios.interceptors.request.use(
    async config => {
      const user = firebase.auth.currentUser;
      if (user) {
        const token = await user.getIdToken();
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    error => {
      return Promise.reject(error);
    },
  );

  useEffect(() => {
    setIsAdmin(isAuthUserAdmin(authUser));
  }, [authUser]);

  const handleOnIdle = () => {
    firebase.doSignOut();
  };
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    dispatch(hideToast());
  };

  useIdleTimer({
    timeout: timeOutInMilliSeconds(isAdmin),
    onIdle: handleOnIdle,
    debounce: 500,
  });

  return (
    <Router>
      <Navigation />
      {toast?.message ? (
        <Snackbar
          open={toast?.open}
          autoHideDuration={3000}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
          <Alert variant="filled" onClose={handleClose} severity={toast?.severity} sx={{ width: '100%' }}>
            {`${toast?.message}`}
          </Alert>
        </Snackbar>
      ) : null}
      <div style={{ margin: '24px' }}>
        <Switch>
          <ProtectedRoute exact path={ROUTES.SIGN_IN} component={SignInPage} />
          <ProtectedRoute path={ROUTES.CONNECT} component={ConnectPage} />
          <Route exact path={ROUTES.RELEASE_NOTES} component={ReleaseNotesPage} />
          <ProtectedRoute path={ROUTES.FORGOT_PASSWORD} component={ForgotPasswordPage} />
          <ProtectedRoute path={ROUTES.CHANGE_PASSWORD} component={PasswordChangePage} />
          <ProtectedRoute path={ROUTES.USERS} component={UsersPage} />
          <ProtectedRoute path={ROUTES.CONTACTS} component={ContactsPage} />
          <ProtectedRoute path={ROUTES.SITES} component={SitesPage} />
          <ProtectedRoute path={ROUTES.DISTRIBUTORS} component={DistributorsPage} />
          <ProtectedRoute path={ROUTES.REPORTS} component={ReportsPage} />
          <ProtectedRoute path={ROUTES.TEST} component={TestPage} />
          <ProtectedRoute path={ROUTES.MESSAGING} component={MessagingPage} />
          <ProtectedRoute path={ROUTES.DEVICES} component={DevicePage} />
          <ProtectedRoute path={ROUTES.TWO_FACTOR} component={TwoFactorPage} />
          <ProtectedRoute path={ROUTES.ORGANIZATIONS} component={OrganizationsPage} />
          <ProtectedRoute path={ROUTES.MONTHLY_BILLING} component={BillingReportPage} />
          <ProtectedRoute path={ROUTES.NOT_FOUND} component={NotFoundPage} />
          <Redirect to="/404" />
        </Switch>
      </div>
    </Router>
  );
};

export default withAuthentication(App);
