import React from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useTranslation } from 'react-i18next';
import { resources } from '../../i18n';

const LanguageSelect = ({ value, onChange, disabled, required }) => {
  const { t } = useTranslation();

  const capitalize = str => {
    try {
      return str.charAt(0).toUpperCase() + str.slice(1);
    } catch (e) {
      return str;
    }
  };
  return (
    <FormControl fullWidth={true}>
      <InputLabel id="language-select-label">{t('email language') + (required ? ' *' : '')}</InputLabel>
      <Select
        labelId="language-select-label"
        id="language-select"
        name="language"
        label="Language"
        onChange={onChange}
        value={value}
        displayEmpty={true}
        fullWidth
        disabled={disabled}
      >
        {Object.entries(resources).map(([language, translation]) => (
          <MenuItem value={language} key={translation.name}>
            {capitalize(t(translation.name))}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default LanguageSelect;
