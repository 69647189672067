import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import MaterialTable from '../MaterialTable/MaterialTable';
import TableIcons from '../../constants/tableIcons';
import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

const PREFIX = 'DeviceTable';

const classes = {
  loadingContainer: `${PREFIX}-loadingContainer`,
};

const Root = styled('div')(() => ({
  [`& .${classes.loadingContainer}`]: {
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    background: 'white',
    opacity: 0.3,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const DeviceTable = ({ data, columns, loading, setPage, setPageSize, totalCount, tableRef, exportCSV }) => {
  const { t } = useTranslation();

  const onChangeColumnHidden = fieldData => {
    localStorage.setItem(`field_${fieldData.field}_hidden`, fieldData.hidden);
  };

  return (
    <Root>
      <MaterialTable
        icons={TableIcons}
        title={t('devices')}
        columns={columns}
        data={data}
        tableRef={tableRef}
        totalCount={totalCount}
        onChangeColumnHidden={onChangeColumnHidden}
        onChangePage={newPage => setPage(newPage)}
        onChangeRowsPerPage={newPageSize => {
          setPageSize(newPageSize);
          setPage(0); // Reset to the first page
        }}
        options={{
          rowStyle: {
            fontSize: 14,
          },
          columnsButton: true,
          maxColumnSort: 0,
          search: false,
          exportMenu: [
            {
              label: t('device_export_csv'),
              exportFunc: (columns, data, tableData) => {
                exportCSV('device_data');
              },
            },
            {
              label: t('software_asset_export_csv'),
              exportFunc: (columns, data, tableData) => {
                exportCSV('software_assets');
              },
            },
            // , {
            //     label: t('export_pdf'),
            //     exportFunc: (cols, data, tableData) => {
            //         if (jsPDF !== null) {
            //             let content = {
            //                 startY: 50,
            //                 head: [exportColumns],
            //                 body: tableData.filteredData.map((row) => getExportRowData(row)),
            //                 headStyles: { fillColor: [0, 132, 61] },
            //                 columnStyles: {
            //                     0: {cellWidth: 50},
            //                     5: {cellWidth: 50},
            //                     7: {cellWidth: 60},
            //                     8: {cellWidth: 80},
            //                     9: {cellWidth: 50},
            //                 }
            //             };
            //
            //             const unit = "pt";
            //             const size = "A4";
            //             const orientation = "landscape";
            //
            //             const doc = new jsPDF(orientation, unit, size);
            //             doc.setFontSize(15);
            //             doc.text("Devices", 40, 40);
            //             doc.autoTable(content);
            //             doc.save("Devices.pdf");
            //         }
            //     }
            // }
          ],
        }}
      />
      {loading && (
        <div className={classes.loadingContainer}>
          <CircularProgress />
        </div>
      )}
    </Root>
  );
};

export default DeviceTable;
