import React from 'react';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import { useTranslation } from 'react-i18next';
import { getDistributorAccessExpiryTime, isDistributorAccessEnabled } from '../../utils/common';

const DistributorAccessButton = ({ server, setTogglingDistributorAccessFor, classes }) => {
  const { t } = useTranslation();

  const getDistributorAccessTooltip = device => {
    if (!isDistributorAccessEnabled(device)) return 'Distributor access is not enabled';
    const expiryTime = getDistributorAccessExpiryTime(device);
    const expiresInSeconds = (expiryTime.getTime() - new Date().getTime()) / 1000;
    return `${Math.floor(expiresInSeconds / 60 ** 2)}h ${Math.floor((expiresInSeconds % 60 ** 2) / 60)}${t(
      'min remaining',
    )}`;
  };

  return (
    <Tooltip title={getDistributorAccessTooltip(server)}>
      <Button
        onClick={() => setTogglingDistributorAccessFor(server)}
        variant="contained"
        className={`${classes.distributorAccessButton} ${
          !isDistributorAccessEnabled(server) && classes.distributorAccessButtonWarning
        }`}
        color={isDistributorAccessEnabled(server) ? 'primary' : 'error'}
      >
        Distributor access: {`${isDistributorAccessEnabled(server) ? t('on').toUpperCase() : t('off').toUpperCase()}`}
      </Button>
    </Tooltip>
  );
};

export default DistributorAccessButton;
