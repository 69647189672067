import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import _ from 'lodash';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import Autocomplete from '@mui/material/Autocomplete';
import * as API from '../../../constants/api';
import { regions } from '../../../constants/regions';
import { useTranslation } from 'react-i18next';
import LanguageSelect from '../../LanguageSelect/LanguageSelect';
import { showToast } from '../../../store/actions/toastAction';
import { useDispatch } from 'react-redux';
import AdornedButton from '../../Button/AdornedButton';
import axios from 'axios';

const PREFIX = 'ContactModal';

const classes = {
  select: `${PREFIX}-select`,
  divider: `${PREFIX}-divider`,
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${classes.select}`]: {
    marginTop: '15px',
  },

  [`& .${classes.divider}`]: {
    margin: theme.spacing(1, 0),
  },
  cirrusProOnly: {
    margin: '20px 6px',
  },
}));

const CONTACT_INITIAL_STATE = {
  email: '',
  name: '',
  region: '',
  sites: [],
  language: '',
};

const ContactModal = ({ reload, show, toggler, selectedContact, sites }) => {
  const sitesArray = _.map(sites, site => ({ uid: site.uid, title: `${site.clientName} - ${site.name}` }));
  if (selectedContact) {
    selectedContact['sites'] = _.map(
      _.filter(sites, site => 'contacts' in site && site.contacts.includes(selectedContact.id)),
      site => ({ uid: site.uid, title: `${site.clientName} - ${site.name}` }),
    );
  }

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [contact, setContact] = useState(CONTACT_INITIAL_STATE);

  useEffect(() => {
    if (selectedContact) {
      setContact(selectedContact);
    } else {
      setContact(CONTACT_INITIAL_STATE);
    }
  }, [selectedContact]);
  const onChange = event => {
    event.preventDefault();
    setContact(prevContact => ({
      ...prevContact,
      [event.target.name]: event.target.value,
    }));
  };

  const onClose = () => {
    toggler(false);
  };

  const onTagsChange = (event, values) => {
    setContact(prevContact => ({
      ...prevContact,
      sites: _.map(values, value => value),
    }));
  };

  const saveContact = async event => {
    try {
      event.preventDefault();
      setLoading(true);
      const postData = {
        id: contact.id,
        name: contact.name,
        email: contact.email,
        region: contact.region,
        language: contact.language || 'en',
        sites: contact.sites.map(site => site.uid),
      };
      await axios.post(API.SAVE_CONTACT, postData);
      if (reload) {
        await reload();
      }
      dispatch(showToast(`Contact with email ${contact.email} saved.`));
      toggler(false);
    } catch (error) {
      dispatch(showToast(error.response.data.message, 'error'));
    } finally {
      setLoading(false);
    }
  };

  const isInvalid = !contact.email || !contact.name || !contact.language || !contact.region;

  return (
    <StyledDialog open={show || false} onClose={onClose}>
      <DialogTitle>{t('new_contact')}</DialogTitle>
      <form noValidate onSubmit={saveContact}>
        <DialogContent>
          <TextField
            margin="dense"
            id="name"
            label={t('name')}
            type="text"
            name="name"
            fullWidth
            required
            autoFocus
            value={contact.name}
            onChange={onChange}
            disabled={loading}
          />
          <TextField
            margin="dense"
            id="email"
            label={t('email')}
            required
            type="email"
            name="email"
            fullWidth
            value={contact.email}
            onChange={onChange}
            disabled={loading}
          />
          <div className={classes.select}>
            <FormControl fullWidth={true}>
              <InputLabel id="region-select-label">{t('region') + ' *'}</InputLabel>
              <Select
                labelId="region-select-label"
                label="Region"
                id="region-select"
                name="region"
                onChange={onChange}
                value={contact.region}
                required
                displayEmpty={true}
                fullWidth
                disabled={loading}
              >
                {_.map(regions, region => (
                  <MenuItem key={region.code} value={region.code}>
                    {region.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className={classes.select}>
            <LanguageSelect value={contact.language} onChange={onChange} required />
          </div>
          <div className={classes.select}>
            <FormControl fullWidth={true}>
              <Autocomplete
                multiple
                id="sites-standard"
                options={sitesArray}
                getOptionLabel={option => option.title}
                isOptionEqualToValue={(option, value) => value.uid === option.uid}
                value={contact.sites}
                onChange={onTagsChange}
                renderInput={params => (
                  <TextField {...params} variant="standard" label={t('sites')} placeholder={t('sites')} name="sites" />
                )}
                renderOption={(props, option) => (
                  <li {...props} key={option.uid}>
                    {option.title}
                  </li>
                )}
              />
            </FormControl>
          </div>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={onClose} disabled={loading}>
            {t('close')}
          </Button>
          <AdornedButton
            variant="contained"
            color="primary"
            type="submit"
            disabled={!!(isInvalid || loading)}
            loading={loading}
          >
            {t('save')}
          </AdornedButton>
        </DialogActions>
      </form>
    </StyledDialog>
  );
};
export default ContactModal;
