const initialState = {
  isUserSigning: false,
  toast: null,
  user: null,
};
export function userReducer(state = initialState, action) {
  switch (action.type) {
    case 'SET_USER_SIGNING':
      return {
        ...state,
        isUserSigning: action.payload,
      };
    case 'UPDATE_USER':
      return {
        ...state,
        user: action.payload,
      };
    case 'UPDATE_TOAST':
      return {
        ...state,
        toast: action.payload,
      };
    default:
      return state;
  }
}
