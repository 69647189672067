import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import _ from 'lodash';

import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import { withFirebase } from '../Firebase';
import TableIcons from '../../constants/tableIcons';
import { useTranslation } from 'react-i18next';
import MaterialTable from '../MaterialTable/MaterialTable';
import Button from '@mui/material/Button';
import AdornedButton from '../Button/AdornedButton';
import SaveMessageDialog from './dialogs/SaveMessageDialog';
import IconButton from '@mui/material/IconButton';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import EditIcon from '@mui/icons-material/Edit';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { format } from 'date-fns';
import Regions from '../../constants/regions.json';
import { useSelector } from 'react-redux';
import { getDevicesFromDatabaseForSelect, getSitesFromDatabase } from '../../utils/common';
import SendNotificationDialog from './dialogs/SendNotificationDialog';
import axios from 'axios';
import * as API from '../../constants/api';
import { getDocs } from 'firebase/firestore';
import { deleteDoc } from '@firebase/firestore';

const PREFIX = 'index';

const classes = {
  table: `${PREFIX}-table`,
  buttonContainer: `${PREFIX}-buttonContainer`,
};

const StyledContainer = styled(Container)(({ theme }) => ({
  [`& .${classes.table}`]: {
    margin: theme.spacing(2, 0),
  },

  [`& .${classes.buttonContainer}`]: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

const MessagingTable = ({ data, columns }) => {
  const { t } = useTranslation();

  return <MaterialTable icons={TableIcons} title={t('messaging')} columns={columns} data={data} />;
};

const MessagingPage = props => {
  const { t } = useTranslation();
  const [error, setError] = useState('');
  const authUser = useSelector(state => state.user);
  const [loading, setLoading] = useState(true);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [messages, setMessages] = useState([]);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [showRemoveMessage, setShowRemoveMessage] = useState(false);
  const [showSaveMessage, setShowSaveMessage] = useState(false);
  const [showSendPushNotificationDialog, setShowSendPushNotificationDialog] = useState(false);
  const [sites, setSites] = useState([]);
  const [pulseOnlineDevices, setPulseOnlineDevices] = useState([]);
  const [clients, setClients] = useState([]);
  const [devices, setDevices] = useState([]);

  const closeRemoveMessage = () => {
    setSelectedMessage(null);
    setShowRemoveMessage(false);
  };

  const deleteMessage = message => {
    setSelectedMessage(message);
    setShowRemoveMessage(true);
  };

  const updateMessage = message => {
    setSelectedMessage(message);
    setShowSaveMessage(true);
  };

  const showAddDialog = () => {
    setSelectedMessage(null);
    setShowSaveMessage(true);
  };

  const removeMessage = async () => {
    try {
      setDeleteLoading(true);
      setShowRemoveMessage(false);
      await deleteDoc(props.firebase.message(selectedMessage.uid));
    } finally {
      setDeleteLoading(false);
      setSelectedMessage(null);
    }
  };

  const getFormatDate = timestamp => {
    return format(timestamp, 'MMM do, yyyy hh:mm a');
  };

  const getSites = async () => {
    const sites = (await getSitesFromDatabase(props.firebase, authUser)).map(site => {
      return {
        id: site.siteid,
        title: `${site.clientName} - ${site.name}`,
      };
    });
    setSites(sites);
  };

  const getClients = async () => {
    const clientSnapshot = await getDocs(props.firebase.clients());
    const newClients = clientSnapshot.docs.map(doc => {
      const docData = doc.data();
      return {
        id: docData.clientid,
        title: docData.name,
      };
    });
    setClients(newClients);
  };

  const getDevices = async () => {
    const devices = (await getDevicesFromDatabaseForSelect(props.firebase)).data.map(device => {
      return {
        id: device.id?.split('.')[1] ?? '',
        title: device.name ?? '',
      };
    });
    setDevices(devices);
  };

  const fetchAutoCompleteList = async () => {
    await Promise.all([getClients(), getSites(), getDevices()]);
  };

  const fetchMessages = async () => {
    const messageSnapshot = await getDocs(props.firebase.messages());
    const newMessages = _.map(messageSnapshot.docs, doc => {
      const messageDoc = doc.data();
      messageDoc['uid'] = doc.id;
      messageDoc['id'] = doc.id;
      return messageDoc;
    });
    setMessages(newMessages);
  };

  async function fetchOnlinePulseDevices() {
    try {
      const res = await axios.get(`${API.GET_PULSE_ONLINE_DEVICES}?environment=${process.env.REACT_APP_ENVIRONMENT}`);
      setPulseOnlineDevices(res.data.deviceIds);
    } catch (e) {
      console.error(e);
    }
  }

  const fetchData = async () => {
    try {
      await Promise.all([fetchAutoCompleteList(), fetchMessages(), fetchOnlinePulseDevices()]);
    } catch (e) {
      setError(String(e));
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      title: t('dashboard'),
      field: 'dashboard',
      render: rowData => {
        return rowData.dashboard
          ?.split(',')
          .map(regionCode => Regions.find(region => region.code === regionCode)?.name)
          .join(', ');
      },
    },
    {
      title: t('client'),
      field: 'client',
      render: rowData => {
        return rowData.client
          ?.split(',')
          .map(clientId => clients.find(client => client.id === clientId)?.title)
          .join(', ');
      },
    },
    {
      title: t('site'),
      field: 'site',
      render: rowData => {
        return rowData.site
          ?.split(',')
          .map(siteId => sites.find(site => site.id === siteId)?.title)
          .join(', ');
      },
    },
    {
      title: t('title'),
      field: 'title',
      cellStyle: {
        verticalAlign: 'top',
        fontWeight: 'bold',
      },
    },
    {
      title: t('schedule'),
      field: 'schedule',
      render: rowData => <div>{getFormatDate(rowData.schedule)}</div>,
    },
    {
      title: '',
      field: 'actions',
      render: rowData => (
        <div>
          <IconButton onClick={() => updateMessage(rowData)} size="small" disabled={loading}>
            <EditIcon color="primary" />
          </IconButton>
          <IconButton onClick={() => deleteMessage(rowData)} size="small" disabled={loading}>
            <HighlightOffIcon color="error" />
          </IconButton>
        </div>
      ),
    },
  ];

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showSaveMessage]);

  return (
    <StyledContainer maxWidth="md">
      {!loading ? (
        <div>
          <div className={classes.buttonContainer}>
            <Button variant="contained" color="primary" onClick={() => showAddDialog()}>
              {t('new message')}
            </Button>
            <Button variant="contained" color="secondary" onClick={() => setShowSendPushNotificationDialog(true)}>
              {t('send_push_notification')}
            </Button>
          </div>
          <SaveMessageDialog
            firebase={props.firebase}
            reload={fetchMessages}
            sites={sites}
            clients={clients}
            devices={devices}
            show={showSaveMessage}
            toggler={setShowSaveMessage}
          />
          <SaveMessageDialog
            firebase={props.firebase}
            reload={fetchMessages}
            sites={sites}
            clients={clients}
            devices={devices}
            show={showSaveMessage}
            toggler={setShowSaveMessage}
            currentMessage={selectedMessage}
          />
          <SendNotificationDialog
            show={showSendPushNotificationDialog}
            toggler={setShowSendPushNotificationDialog}
            devices={devices}
            pulseOnlineDevices={pulseOnlineDevices}
          />
          <div className={classes.table}>
            <MessagingTable columns={columns} data={messages} />
          </div>
          {selectedMessage ? (
            <Dialog open={showRemoveMessage} onClose={() => closeRemoveMessage()}>
              <DialogContent>{t('are you sure to remove this message')}</DialogContent>
              <DialogActions>
                <AdornedButton
                  variant="contained"
                  color="secondary"
                  onClick={() => closeRemoveMessage()}
                  disabled={loading || deleteLoading}
                >
                  {t('no')}
                </AdornedButton>
                <AdornedButton
                  variant="contained"
                  color="primary"
                  onClick={() => removeMessage()}
                  disabled={loading || deleteLoading}
                  loading={deleteLoading}
                >
                  {t('yes')}
                </AdornedButton>
              </DialogActions>
            </Dialog>
          ) : null}
          {error ? (
            <Typography variant="subtitle2" color="error">
              {error}
            </Typography>
          ) : null}
        </div>
      ) : (
        <div style={{ textAlign: 'center' }}>
          <CircularProgress />
        </div>
      )}
    </StyledContainer>
  );
};
export default withFirebase(MessagingPage);
