import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import AdornedButton from '../../Button/AdornedButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { useTranslation } from 'react-i18next';
import Autocomplete from '@mui/material/Autocomplete';
import axios from 'axios';
import * as API from '../../../constants/api';
import { showToast } from '../../../store/actions/toastAction';
import { useDispatch } from 'react-redux';
import { downloadFileFromUrl } from '../../../utils/common';

const PREFIX = 'GenerateTestReportDialog';

const classes = {
  select: `${PREFIX}-select`
};

const StyledDialog = styled(Dialog)((
  {
    theme
  }
) => ({
  [`& .${classes.select}`]: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
  }
}));

const GenerateTestReportDialog = ({ show, toggler, sites }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const [selectedSite, setSelectedSite] = useState({});

  const onClose = () => {
    toggler(false);
  };

  const generateReport = async event => {
    event.preventDefault();
    try {
      setLoading(true);
      const res = await axios.get(`${API.GENERATE_TEST_REPORT}?site_id=${selectedSite.id}`, { responseType: 'blob' });
      const blobURL = URL.createObjectURL(res.data);
      await downloadFileFromUrl(blobURL, `${selectedSite.title}.pdf`);
      toggler(false);
    } catch (e) {
      dispatch(showToast(`${e}`, 'error'));
    } finally {
      setLoading(false);
    }
  };

  return (
    <StyledDialog open={show || false} onClose={onClose}>
      <DialogTitle>{t('generate_monthly_test_report')}</DialogTitle>
      <form noValidate>
        <DialogContent>
          <Autocomplete
            id="sites-standard"
            options={sites}
            getOptionLabel={option => option.title ?? ''}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onChange={(event, value) => setSelectedSite(value)}
            value={selectedSite}
            className={classes.select}
            renderInput={params => (
              <TextField {...params} variant="standard" label={t('sites')} placeholder={t('sites')} name="sites" />
            )}
            renderOption={(props, option) => (
              <li {...props} key={option.id}>
                {option.title ?? ''}
              </li>
            )}
          />
        </DialogContent>
        <DialogActions>
          <AdornedButton
            variant="contained"
            color="primary"
            onClick={generateReport}
            disabled={loading || !selectedSite?.id}
            loading={loading}
          >
            {t('generate_monthly_test_report')}
          </AdornedButton>
          <AdornedButton variant="contained" onClick={onClose} disabled={loading}>
            {t('close')}
          </AdornedButton>
        </DialogActions>
      </form>
    </StyledDialog>
  );
};
export default GenerateTestReportDialog;
