import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import Container from '@mui/material/Container';
import { useTranslation } from 'react-i18next';
import { withFirebase } from '../Firebase';
import TextField from '@mui/material/TextField';
import AdornedButton from '../Button/AdornedButton';
import * as API from '../../constants/api';
import { showErrorAlert, showSuccessAlert } from '../../utils/common';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { updateUser } from '../../store/actions/userAction';
import * as ROUTES from '../../constants/routes';
import axios from 'axios';

const PREFIX = 'index';

const classes = {
  buttonContainer: `${PREFIX}-buttonContainer`,
};

const StyledContainer = styled(Container)(() => ({
  [`& .${classes.buttonContainer}`]: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '15px',
  },
}));

const TwoFactorPage = props => {
  const [code, setCode] = useState('');
  const [isResendLoading, setIsResendLoading] = useState(false);
  const [isValidateLoading, setIsValidateLoading] = useState(false);
  const { t } = useTranslation();
  const authUser = useSelector(state => state.user);
  const dispatch = useDispatch();
  const history = useHistory();

  const resendVerificationCode = async () => {
    try {
      setIsResendLoading(true);
      setCode('');
      await axios.post(API.RESEND_CODE, {});
      showSuccessAlert(t('verification_code_sent_successfully'));
    } catch {
      showErrorAlert(t('error_resend_verification_code'));
    } finally {
      setIsResendLoading(false);
    }
  };

  const verifyCode = async () => {
    try {
      setIsValidateLoading(true);
      await axios.post(API.VALIDATE_CODE, {
        code,
      });
      dispatch(
        updateUser({
          ...authUser,
          isTwoFactorAuthorized: true,
        }),
      );
      const route = authUser.role === 'distributor' ? ROUTES.DEVICES : ROUTES.CONNECT;
      history.push(route);
    } catch (err) {
      let errorMessage;
      if (!err.response) {
        errorMessage = err.message;
      } else if (err.response.status === 400) {
        errorMessage = t('invalid_code');
      } else if (err.response.status === 600) {
        errorMessage = t('2fa_code_expired');
      } else {
        errorMessage = t('error_verify');
      }
      showErrorAlert(errorMessage);
    } finally {
      setCode('');
      setIsValidateLoading(false);
    }
  };

  return (
    <StyledContainer component="main" maxWidth="sm">
      <div>
        <h3 className={classes.titleLabel}>{t('2fa_title')}</h3>
        <span className={classes.subTitleLabel}>{t('2fa_subtitle')}</span>
      </div>
      <TextField
        margin="dense"
        id="code"
        label={t('code')}
        type="number"
        onInput={e => {
          e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 6);
        }}
        name="code"
        fullWidth
        value={code}
        onChange={event => setCode(event.target.value)}
        onKeyUp={e => {
          if (e.key === 'Enter') {
            e.preventDefault();
            if (e.target.value?.length === 6) {
              void verifyCode();
            }
          }
        }}
        autoFocus
      />
      <div className={classes.buttonContainer}>
        <AdornedButton variant="contained" color="secondary" onClick={resendVerificationCode} loading={isResendLoading}>
          {t('resend_verification_code')}
        </AdornedButton>
        <AdornedButton
          variant="contained"
          color="primary"
          onClick={verifyCode}
          loading={isValidateLoading}
          disabled={!code || code?.length !== 6}
        >
          {t('verify_code')}
        </AdornedButton>
      </div>
    </StyledContainer>
  );
};

export default withFirebase(TwoFactorPage);
