import Swal from 'sweetalert2';
import i18n from '../i18n';
import { APP_GREEN_COLOR } from '../constants/const';
import axios from 'axios';
import * as API from '../constants/api';
import { TAKE_CONTROL } from '../constants/api';
import _ from 'lodash';
import { getDoc, getDocs, query, where } from 'firebase/firestore';

export const APP_PERMISSIONS = ['devices', 'messaging', 'test'];
export const APP_RESOURCES = ['au', 'eu', 'us'];

export const REGION_LOOKUP = {
  au: 'AU',
  eu: 'EU',
  us: 'US',
};

function getBackupArr(backup_history) {
  let backups = [];
  try {
    if (backup_history) {
      backups = JSON.parse(backup_history);
    }
  } catch {
    // Error occurred while parsing
  }
  return backups;
}

export function isDeviceOnline(device) {
  return _.toInteger(device.online) && !device.overdue;
}

export function getLast5Backups(backup_history) {
  let backups = getBackupArr(backup_history);
  return backups.slice(Math.max(backups.length - 5, 1));
}

export async function downloadFileFromUrl(url, filename) {
  const link = document.createElement('a');
  link.href = url;
  if (filename) {
    link.setAttribute('download', filename);
  } else {
    link.setAttribute('download', '');
  }
  document.body.appendChild(link);
  link.click();
  link.remove();
}

export function showErrorAlert(message) {
  Swal.fire({
    icon: 'error',
    title: i18n.t('oops'),
    confirmButtonText: i18n.t('ok'),
    confirmButtonColor: APP_GREEN_COLOR,
    text: message,
  });
}

export function showSuccessAlert(message) {
  Swal.fire({
    icon: 'success',
    title: i18n.t('success'),
    confirmButtonText: i18n.t('ok'),
    confirmButtonColor: APP_GREEN_COLOR,
    text: message,
  });
}

export function getDistributorAccessExpiryTime(device) {
  const accessGrantedAt = new Date(device.distributorAccessEnabledAt);
  accessGrantedAt.setHours(accessGrantedAt.getHours() + 2);
  return accessGrantedAt;
}

export function canConnectToDevice(authUser, device) {
  return isAuthUserAdmin(authUser) || isDistributorAccessEnabled(device);
}

export function isDistributorAccessEnabled(device) {
  return device?.distributorAccessEnabledAt && new Date() < getDistributorAccessExpiryTime(device);
}

export async function getUserData(firebase, userId) {
  const idToken = await firebase.auth.currentUser.getIdToken();
  const userDoc = await getDoc(firebase.user(userId));
  const docData = userDoc.data();
  const authUser = {
    uid: userId,
    token: idToken,
  };
  let isTwoFactorAuthorized;
  const twoFactorDocs = await getDocs(firebase.twoFactor(idToken));
  if (twoFactorDocs.size > 0) {
    isTwoFactorAuthorized = Boolean(twoFactorDocs.docs[0].data().verified);
  }
  await i18n.changeLanguage(docData.interfaceLanguage || docData.language || 'en');
  authUser.name = docData.name;
  authUser.accessToken = docData.accessToken;
  authUser.region = docData.region;
  authUser.language = docData.language;
  authUser.interfaceLanguage = docData.interfaceLanguage;
  authUser.cirrusProOnly = docData.cirrusProOnly;
  authUser.email = docData.email;
  authUser.role = docData.role;
  authUser.permissions = docData.permissions;
  authUser.resources = docData.resources;
  authUser.unsubscribedNotifications = docData.unsubscribedNotifications;
  authUser.isTwoFactorAuthorized = isTwoFactorAuthorized;
  authUser.phoneNumber = docData.phoneNumber ?? '';

  if ('organization' in docData) {
    try {
      const orgDoc = await getDoc(docData.organization);
      authUser.organization = orgDoc.data()?.name;
    } catch (e) {
      console.log(e);
    }
  }
  return authUser;
}

// Users with only connect timeout should be 8 hours (480 minutes)
// Uses (with admin permissions) timeout should be 60 minutes
export function timeOutInMilliSeconds(isAdmin) {
  return (isAdmin ? 60 : 480) * 60 * 1000;
}

export function isAuthUserAdmin(authUser) {
  return authUser?.role === 'admin' || authUser?.role === 'system_admin' || authUser?.role === 'super_admin';
}

export async function getSitesFromDatabase(firebase, authUser) {
  const allowedRegions = getAllowedRegions(authUser);
  if (allowedRegions.length < 1) {
    return [];
  }
  const sitesQuery = query(firebase.sites(), where('region', 'in', allowedRegions));

  const siteSnapshot = await getDocs(sitesQuery);
  return siteSnapshot.docs.map(doc => {
    return {
      uid: doc.id,
      id: doc.id,
      ...doc.data(),
    };
  });
}

export async function getDevicesFromDatabaseForSelect() {
  return await axios.get(`${API.GET_DEVICES_FOR_SELECT}`);
}
export function getRoleListFromRole(role) {
  let roles = ['user'];
  if (role === 'system_admin') {
    roles = ['system_admin', 'super_admin', 'admin', 'user', 'distributor'];
  }
  if (role === 'super_admin') {
    roles = ['admin', 'user', 'distributor'];
  }
  return roles;
}

export function checkIfPermissionIsGranted(permission, authUser, extraAuthorizedRoles = []) {
  if (authUser.role === 'system_admin' || authUser.role === 'super_admin') {
    return true;
  }
  if (extraAuthorizedRoles.includes(authUser.role)) {
    return true;
  }
  if (permission === 'admin') {
    return authUser.role === 'admin';
  }
  return authUser.role === 'admin' && (authUser.permissions ?? '').includes(permission);
}

export function getAllowedRegions(authUser) {
  if (authUser.role === 'system_admin' || authUser.role === 'super_admin') {
    return APP_RESOURCES;
  }
  return (authUser.resources ?? '').split(',');
}

export const replaceError = value => {
  if (typeof value === 'number') {
    return value;
  }
  if (typeof value !== 'string') {
    return '';
  }
  if (value.length < 2) {
    return '';
  }
  const exceptions = [
    'Thread failed to start.',
    'Script not found',
    'Script timed out',
    'Script awaiting download',
    'Script output not found',
    'Script failed to start.',
  ];
  if (exceptions.some(exception => exception.includes(value))) {
    return '';
  }
  if (/[e|E]xception/.test(value ?? '') || value?.includes('GetResponse') || value?.length > 40 || value === ',') {
    return '';
  }
  return value;
};

export const getTakeControlURL = async (authUser, server, type) => {
  const takeControlUrl = TAKE_CONTROL;
  const searchParams = new URLSearchParams();
  searchParams.append('userID', authUser.uid);
  searchParams.append('accessToken', authUser.accessToken);
  searchParams.append('deviceid', server.serverid);
  searchParams.append('region', server.region);
  searchParams.append('type', type);

  const { data } = await axios.get(takeControlUrl + searchParams);

  if (!data.takeControlURL) {
    throw new Error('Invalid response. Please try again');
  }
  return data.takeControlURL;
};

export const isOffline = server => {
  return _.toInteger(server?.online) === 0 || (_.toInteger(server?.online) === 1 && server?.overdue);
};

export const getErrorMsg = e => {
  const errResponse = e.response?.data;
  if (errResponse) {
    return errResponse.error ?? errResponse.message ?? `${errResponse}`;
  }
  return `${e}`;
};

export const getErrorTextForTakeControl = err => {
  let errorText = 'connection error';
  if (err.response) {
    if (err.response.status === 401) {
      errorText = 'You are not authorized to control this device';
    } else if (err.response.data) {
      errorText = err.response.data;
    }
  } else if (err.message) {
    errorText = err.message;
  }
  return errorText;
};

export const getErrorTextForTunnel = err => {
  let errorText = 'Error connecting to tunnel';
  if (err.response) {
    if (err.response.status === 404) {
      errorText = 'Device not found';
    } else if (err.response.status === 400) {
      errorText = err.response.data.message || 'Invalid request';
    } else if (err.response.status === 500) {
      errorText = 'Server error. Please try again later';
    } else if (err.response.data && err.response.data.message) {
      errorText = err.response.data.message;
    }
  } else if (err.message) {
    errorText = err.message;
  }
  return errorText;
};
