import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import AdornedButton from '../../Button/AdornedButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import MenuItem from '@mui/material/MenuItem';
import _ from 'lodash';
import Select from '@mui/material/Select';
import { useTranslation } from 'react-i18next';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { regions } from '../../Users/regions';
import * as API from '../../../constants/api';
import { CsvBuilder } from 'filefy';
import Autocomplete from '@mui/material/Autocomplete';
import CountryCodes from '../../../constants/country-codes.json';
import Regions from '../../../constants/regions.json';
import { showToast } from '../../../store/actions/toastAction';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { getDoc } from 'firebase/firestore';

const PREFIX = 'SaveMessageDialog';

const classes = {
  select: `${PREFIX}-select`
};

const StyledDialog = styled(Dialog)((
) => ({
  [`& .${classes.select}`]: {
    marginTop: '15px'
  }
}));

const ADD_MESSAGE_INITIAL_STATE = {
  dashboard: regions[0].code,
  client: '',
  site: [],
  device: '',
  country: '',
  city: '',
  title: '',
  html: '',
  schedule: new Date(),
  repeat: 'once',
};

const SaveMessageDialog = ({ show, reload, toggler, firebase, sites, clients, devices, currentMessage }) => {
  const { t } = useTranslation();


  const repeatArr = [
    { label: 'Once', value: 'once' },
    { label: 'Daily', value: 'daily' },
    { label: 'Weekly', value: 'weekly' },
    { label: 'Monthly', value: 'monthly' },
  ];
  const dispatch = useDispatch();
  const [selectedRegions, setSelectedRegions] = useState([]);
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [selectedSites, setSelectedSites] = useState([]);
  const [selectedClients, setSelectedClients] = useState([]);
  const [selectedDevices, setSelectedDevices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isGetFilteredBtnLoading, setIsGetFilteredBtnLoading] = useState(false);
  const [selectedDate, handleDateChange] = useState(new Date());

  const [message, setMessage] = useState(ADD_MESSAGE_INITIAL_STATE);

  useEffect(() => {
    if (currentMessage) {
      handleDateChange(currentMessage.schedule);
      setMessage(currentMessage);
      setSelectedSites(
        currentMessage.site
          ?.split(',')
          .map(siteId => sites.find(site => site.id === siteId))
          .filter(site => site !== undefined),
      );
      setSelectedClients(
        currentMessage.client
          ?.split(',')
          .map(clientId => clients.find(client => client.id === clientId))
          .filter(client => client !== undefined),
      );
      setSelectedDevices(
        currentMessage.device
          ?.split(',')
          .map(deviceId => devices.find(device => device.id === deviceId))
          .filter(device => device !== undefined),
      );
      setSelectedCountries(
        currentMessage.country
          ?.split(',')
          .map(countryCode => CountryCodes.find(country => country.code === countryCode))
          .filter(country => country !== undefined),
      );
      setSelectedRegions(
        currentMessage.dashboard
          ?.split(',')
          .map(regionCode => Regions.find(region => region.code === regionCode))
          .filter(region => region !== undefined),
      );
    } else {
      handleDateChange(new Date());
      setMessage(ADD_MESSAGE_INITIAL_STATE);
      setSelectedClients([]);
      setSelectedDevices([]);
      setSelectedRegions([]);
      setSelectedCountries([]);
      setSelectedSites([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentMessage, show]);

  const onChange = event => {
    event.persist();
    setMessage(prevMessage => ({
      ...prevMessage,
      [event.target.name]: event.target.value,
    }));
  };

  const onClientChange = (event, values) => {
    event.persist();
    setSelectedClients(values);
  };

  const onClose = () => {
    toggler(false);
    setMessage(ADD_MESSAGE_INITIAL_STATE);
  };

  const generateCSV = async () => {
    setIsGetFilteredBtnLoading(true);
    const columns = ['Region', 'Client', 'Site', 'Device Name', 'Country', 'City', 'Software'];
    try {
      const params = new URLSearchParams({
        dashboard: selectedRegions.map(region => region.code).join(','),
        client: selectedClients.map(client => client.id).join(','),
        site: selectedSites.map(site => site.id).join(','),
        device: selectedDevices.map(device => device.id).join(','),
        country: selectedCountries.map(country => country.code).join(','),
        city: message.city,
      });
      const list = (await axios.get(API.GET_FILTERED_LIST + params)).data;
      const fileName = 'Filtered_Devices';
      const builder = new CsvBuilder(fileName + '.csv');
      builder
        .setDelimeter(',')
        .setColumns(columns)
        .addRows(
          list.map(row => {
            return [
              row.region?.toUpperCase(),
              row.clientName,
              row.siteName,
              row.name,
              typeof row.country === 'string' ? row.country : '',
              typeof row.city === 'string' ? row.city : '',
              typeof row.software === 'string' ? row.software : '',
            ];
          }),
        )
        .exportFile();
    } catch (e) {
      dispatch(showToast(`${e}`, 'error'));
    } finally {
      setIsGetFilteredBtnLoading(false);
    }
  };

  const saveMessage = async event => {
    setLoading(true);
    firebase
      .message(message.uid ?? (await getDoc(firebase.messages())).id)
      .set({
        dashboard: selectedRegions.map(region => region.code).join(','),
        client: selectedClients.map(client => client.id).join(','),
        site: selectedSites.map(site => site.id).join(','),
        country: selectedCountries.map(country => country.code).join(','),
        device: selectedDevices.map(device => device.id).join(','),
        city: message.city,
        title: message.title,
        html: message.html,
        schedule: selectedDate.valueOf(),
        repeat: message.repeat,
      })
      .then(() => {
        dispatch(showToast(`Message created successfully`));
        setLoading(false);
        reload();
        setTimeout(() => {
          onClose();
        }, 100);
      })
      .catch(error => {
        setLoading(false);
        dispatch(showToast(`${error}`, 'error'));
      });
    event.preventDefault();
  };

  const isInvalid = !message.title || !message.html;

  return (
    <StyledDialog open={show || false} onClose={onClose}>
      <DialogTitle>{t(message.uid ? 'update message' : 'new message')}</DialogTitle>
      <form noValidate onSubmit={saveMessage}>
        <DialogContent>
          <Autocomplete
            multiple
            id="dashboard-standard"
            options={Regions}
            getOptionLabel={option => option.name}
            isOptionEqualToValue={(option, value) => option.code === value.code}
            onChange={(event, values) => setSelectedRegions(values)}
            value={selectedRegions}
            className={classes.select}
            renderInput={params => (
              <TextField
                {...params}
                variant="standard"
                label={t('dashboard')}
                placeholder={t('dashboard')}
                name="dashboard"
              />
            )}
            renderOption={(props, option) => (
              <li {...props} key={option.id}>
                {option.name}
              </li>
            )}
          />
          <Autocomplete
            multiple
            id="clients-standard"
            options={clients}
            getOptionLabel={option => option.title}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onChange={onClientChange}
            value={selectedClients}
            className={classes.select}
            renderInput={params => (
              <TextField {...params} variant="standard" label={t('client')} placeholder={t('client')} name="clients" />
            )}
          />
          <Autocomplete
            multiple
            id="sites-standard"
            options={sites}
            getOptionLabel={option => option.title}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onChange={(event, values) => setSelectedSites(values)}
            value={selectedSites}
            className={classes.select}
            renderInput={params => (
              <TextField {...params} variant="standard" label={t('sites')} placeholder={t('sites')} name="sites" />
            )}
          />
          <Autocomplete
            multiple
            id="device-standard"
            options={devices}
            getOptionLabel={option => option.title}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onChange={(event, values) => setSelectedDevices(values)}
            value={selectedDevices}
            className={classes.select}
            renderInput={params => (
              <TextField {...params} variant="standard" label={t('device')} placeholder={t('device')} name="device" />
            )}
            renderOption={(props, option) => (
              <li {...props} key={option.id}>
                {option.title}
              </li>
            )}
          />
          <Autocomplete
            multiple
            id="country-standard"
            options={CountryCodes}
            getOptionLabel={option => option.name}
            isOptionEqualToValue={(option, value) => option.code === value.code}
            onChange={(event, values) => setSelectedCountries(values)}
            value={selectedCountries}
            className={classes.select}
            renderInput={params => (
              <TextField
                {...params}
                variant="standard"
                label={t('country')}
                placeholder={t('country')}
                name="country"
              />
            )}
          />
          <TextField
            margin="dense"
            id="city"
            label={t('city')}
            type="text"
            name="city"
            fullWidth
            value={message.city}
            onChange={onChange}
            autoFocus
            disabled={loading}
          />
          <TextField
            margin="dense"
            id="title"
            label={t('title')}
            type="text"
            name="title"
            fullWidth
            value={message.title}
            onChange={onChange}
            autoFocus
            disabled={loading}
          />
          <TextField
            margin="dense"
            id="html"
            label={t('html')}
            type="text"
            name="html"
            fullWidth
            multiline
            minRows={4}
            value={message.html}
            onChange={onChange}
            autoFocus
            disabled={loading}
          />
          <div className={classes.select}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateTimePicker
                id="schedule"
                label={t('schedule')}
                name="schedule"
                fullWidth
                onChange={handleDateChange}
                value={selectedDate}
                disabled={loading}
                renderInput={props => <TextField {...props} />}
              />
            </LocalizationProvider>
          </div>
          <div className={classes.select}>
            <FormControl fullWidth={true}>
              <InputLabel id="repeat-select-label">{t('repeat')}</InputLabel>
              <Select
                labelId="repeat-select-label"
                id="repeat-select"
                name="repeat"
                onChange={onChange}
                label="Repeat"
                value={message.repeat}
                displayEmpty={true}
                fullWidth
                disabled={loading}
              >
                {_.map(repeatArr, repeatOption => (
                  <MenuItem key={repeatOption.value} value={repeatOption.value}>
                    {repeatOption.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </DialogContent>
        <DialogActions>
        <AdornedButton
            variant="contained"
            color="secondary"
            onClick={generateCSV}
            disabled={loading}
            loading={isGetFilteredBtnLoading}
          >
            {t('generate_filtered_list')}
          </AdornedButton>
          <AdornedButton variant="contained" onClick={onClose} disabled={loading}>
            {t('close')}
          </AdornedButton>
          <AdornedButton
            variant="contained"
            color="primary"
            type="submit"
            disabled={isInvalid || loading}
            loading={loading}
          >
            {t(message.uid ? 'update' : 'create')}
          </AdornedButton>
        </DialogActions>
      </form>
    </StyledDialog>
  );
};
export default SaveMessageDialog;
