import React, { useState } from 'react';
import _ from 'lodash';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Autocomplete from '@mui/material/Autocomplete';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { showToast } from '../../../store/actions/toastAction';
import AdornedButton from '../../Button/AdornedButton';
import { updateDoc } from 'firebase/firestore';
import { arrayUnion } from '@firebase/firestore';

const AddContactToSite = ({ contacts, site, firebase, show, toggler, reload }) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [contactToAdd, setContactToAdd] = useState({});
  const contactsArray = _.filter(contacts, contact => (site.contacts ? !site.contacts.includes(contact.uid) : true));

  const addContact = async () => {
    if (!contactToAdd?.uid) {
      return;
    }
    try {
      setLoading(true);
      await updateDoc(firebase.site(site.uid), {
        contacts: arrayUnion(contactToAdd.uid),
      });
      await reload();
      dispatch(showToast(t('contact_add_to_site_success')));
      toggler(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog
      open={show}
      onClose={() => {
        toggler(false);
      }}
    >
      <DialogTitle>{`${site.clientName || ''} - ${site.name || ''} - ${t('add_contact')}`}</DialogTitle>
      <DialogContent>
        <Autocomplete
          id="contacts-list"
          options={contactsArray}
          getOptionLabel={option => `${option.name || ''} - ${option.email || ''}`}
          onChange={(event, newValue) => {
            setContactToAdd(newValue);
          }}
          renderInput={params => <TextField {...params} label={t('contacts')} variant="standard" />}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={() => toggler(false)}>
          {t('cancel')}
        </Button>
        <AdornedButton
          variant="contained"
          color="primary"
          onClick={() => addContact()}
          disabled={loading}
          loading={loading}
        >
          {t('add')}
        </AdornedButton>
      </DialogActions>
    </Dialog>
  );
};
export default AddContactToSite;
