import React from 'react';

import Button from '@mui/material/Button';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { withFirebase } from '../Firebase';
import { useTranslation } from 'react-i18next';

const SignOutButton = ({ firebase }) => {
  const signOut = () => {
    firebase.doSignOut();
  };

  return (
    <Button color="inherit" type="button" onClick={signOut}>
      {'Sign out'}
    </Button>
  );
};

const SignOutIconButton = ({ firebase }) => {
  const { t } = useTranslation();

  return (
    <ListItem button onClick={firebase.doSignOut}>
      <ListItemIcon>
        <ExitToAppIcon />
      </ListItemIcon>
      <ListItemText>{t('sign out')}</ListItemText>
    </ListItem>
  );
};

const EnhancedSignOutIconButton = withFirebase(SignOutIconButton);

export default withFirebase(SignOutButton);

export { EnhancedSignOutIconButton };
