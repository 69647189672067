import React, { useEffect, useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import _ from 'lodash';

import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import IconButton from '@mui/material/IconButton';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { withFirebase } from '../Firebase';
import TableIcons from '../../constants/tableIcons';
import AddUserToSite from './dialogs/AddUserToSite';
import UpdateUserDialog from '../Users/dialogs/UpdateUser';
import { useTranslation } from 'react-i18next';
import MaterialTable from '../MaterialTable/MaterialTable';
import UpdateSiteDialog, { getSettingsArrayFromSite } from './dialogs/UpdateSite';
import EditIcon from '@mui/icons-material/Edit';
import { getSitesFromDatabase, REGION_LOOKUP } from '../../utils/common';
import { useSelector } from 'react-redux';
import { getDocs, updateDoc } from 'firebase/firestore';
import { arrayRemove } from '@firebase/firestore';
import ContactDialog from '../Contacts/dialogs/ContactModal';
import AddContactToSite from './dialogs/AddContactToSite';

const PREFIX = 'index';

const classes = {
  nameLink: `${PREFIX}-nameLink`,
  table: `${PREFIX}-table`,
  select: `${PREFIX}-select`,
  adminLine: `${PREFIX}-adminLine`,
  adminRemove: `${PREFIX}-adminRemove`,
  adminBlock: `${PREFIX}-adminBlock`,
  actionBlock: `${PREFIX}-actionBlock`,
  pdfButton: `${PREFIX}-pdfButton`,
};

const StyledContainer = styled(Container)(({ theme }) => ({
  [`& .${classes.nameLink}`]: {
    cursor: 'pointer',
    display: 'block',
    fontSize: 14,
  },

  [`& .${classes.table}`]: {
    margin: theme.spacing(2, 0),
  },

  [`& .${classes.select}`]: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
  },

  [`& .${classes.adminLine}`]: {
    display: 'inline-block',
    verticalAlign: 'top',
  },

  [`& .${classes.adminRemove}`]: {
    display: 'inline-block',
    verticalAlign: 'top',
    float: 'right',
  },

  [`& .${classes.adminBlock}`]: {
    marginBottom: 10,
    borderBottom: '1px solid #e0e0e0',
  },

  [`& .${classes.actionBlock}`]: {
    display: 'flex',
  },

  [`& .${classes.pdfButton}`]: {
    marginLeft: '10px',
  },
}));

const SitesTable = ({ data, columns }) => {
  const { t } = useTranslation();

  return (
    <MaterialTable
      icons={TableIcons}
      title={t('sites')}
      columns={columns}
      data={data}
      options={{
        filtering: true,
      }}
    />
  );
};

const UserCell = ({ adminData, setUpdateUser, deleteUser, rowData }) => {
  const [hover, setHover] = useState(false);

  return (
    <div className={classes.adminBlock} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
      <div className={classes.adminLine}>
        <Link className={classes.nameLink} onClick={() => setUpdateUser(adminData)}>
          {adminData.name || ''}
        </Link>
        <Typography variant="caption">{`${adminData.email}` || ''}</Typography>
      </div>
      {hover ? (
        <div className={classes.adminRemove}>
          <IconButton size="small" onClick={() => deleteUser(rowData, adminData)}>
            <HighlightOffIcon color="error" />
          </IconButton>
        </div>
      ) : null}
    </div>
  );
};

const ContactCell = ({ contact, deleteContact, rowData, setUpdateContact }) => {
  const [hover, setHover] = useState(false);

  return (
    <div className={classes.adminBlock} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
      <div className={classes.adminLine}>
        <Link className={classes.nameLink} onClick={() => setUpdateContact(contact)}>
          {contact.name || ''}
        </Link>
        <Typography variant="caption">{`${contact.email}` || ''}</Typography>
      </div>
      {hover ? (
        <div className={classes.adminRemove}>
          <IconButton size="small" onClick={() => deleteContact(rowData, contact)}>
            <HighlightOffIcon color="error" />
          </IconButton>
        </div>
      ) : null}
    </div>
  );
};

const SitesPage = props => {
  const { t } = useTranslation();
  const authUser = useSelector(state => state.user);
  const [users, setUsers] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [sites, setSites] = useState([]);
  const [organizations, setOrganizations] = useState([]);

  const [showRemoveUser, setShowRemoveUser] = useState(false);
  const [showRemoveContact, setShowRemoveContact] = useState(false);
  const [showAddUser, setShowAddUser] = useState(false);
  const [showAddContact, setShowAddContact] = useState(false);
  const [showUpdateSite, setShowUpdateSite] = useState(false);
  const [userToRemove, setUserToRemove] = useState(null);
  const [contactToRemove, setContactToRemove] = useState(null);
  const [selectedSite, setSelectedSite] = useState(null);

  // Update user related code
  const [showUpdateUser, setShowUpdateUser] = useState(false);
  const [showUpdateContact, setShowUpdateContact] = useState(false);
  const [updateUser, setUpdateUser] = useState(null);
  const [updateContact, setUpdateContact] = useState(null);

  const usersRef = useRef(users);
  const contactsRef = useRef(contacts);
  const updateSite = site => {
    setSelectedSite(site);
    setShowUpdateSite(true);
  };

  useEffect(() => {
    setShowUpdateUser(true);
  }, [updateUser]);

  useEffect(() => {
    setShowUpdateContact(true);
  }, [updateContact]);

  const removeUser = async () => {
    await updateDoc(props.firebase.site(selectedSite.uid), {
      administrators: arrayRemove(userToRemove.uid),
    });

    const newSites = [...sites].map(site => {
      if (site.uid === selectedSite.uid) {
        return {
          ...site,
          administrators: site.administrators?.filter(uId => uId !== userToRemove.uid),
        };
      }
      return site;
    });
    setSites(newSites);
    onClose();
  };

  const removeContact = async () => {
    await updateDoc(props.firebase.site(selectedSite.uid), {
      contacts: arrayRemove(contactToRemove.uid),
    });

    const newSites = [...sites].map(site => {
      if (site.uid === selectedSite.uid) {
        return {
          ...site,
          contacts: site.contacts?.filter(uId => uId !== contactToRemove.uid),
        };
      }
      return site;
    });
    setSites(newSites);
    onClose();
  };

  useEffect(() => {
    usersRef.current = users;
  }, [users]);

  useEffect(() => {
    contactsRef.current = contacts;
  }, [contacts]);

  const onClose = () => {
    setUserToRemove(null);
    setContactToRemove(null);
    setSelectedSite(null);
    setShowRemoveUser(false);
    setShowRemoveContact(false);
  };

  const deleteUser = (site, user) => {
    setSelectedSite(site);
    setUserToRemove(user);
  };

  const deleteContact = (site, contact) => {
    setSelectedSite(site);
    setContactToRemove(contact);
  };

  const addUser = site => {
    setSelectedSite(site);
    setShowAddUser(true);
  };

  const addContact = site => {
    setSelectedSite(site);
    setShowAddContact(true);
  };

  useEffect(() => {
    setShowRemoveUser(true);
  }, [userToRemove]);

  useEffect(() => {
    setShowRemoveContact(true);
  }, [contactToRemove]);

  const [columns] = useState([
    {
      title: t('region'),
      field: 'region',
      width: '60px',
      lookup: REGION_LOOKUP,
      render: rowData => rowData.region?.toUpperCase(),
    },
    {
      title: t('client'),
      field: 'clientName',
    },
    {
      title: t('site'),
      field: 'name',
    },
    {
      title: t('users'),
      field: 'userList',
      width: 300,
      render: rowData => (
        <div>
          {_.map(rowData.administrators, admin => {
            const adminData = _.filter(usersRef.current, user => user.uid === admin)[0];
            return adminData ? (
              <UserCell
                key={admin}
                admin={admin}
                adminData={adminData}
                setUpdateUser={setUpdateUser}
                deleteUser={deleteUser}
                rowData={rowData}
              />
            ) : null;
          })}
          <IconButton onClick={() => addUser(rowData)} size="large">
            <PersonAddIcon />
          </IconButton>
        </div>
      ),
    },
    {
      title: t('contacts'),
      field: 'contactList',
      width: 300,
      render: rowData => (
        <div>
          {_.map(rowData.contacts, contact => {
            const contactData = _.filter(contactsRef.current, contact1 => contact1.uid === contact)[0];
            return contactData ? (
              <ContactCell
                key={contact}
                contact={contactData}
                deleteContact={deleteContact}
                rowData={rowData}
                setUpdateContact={setUpdateContact}
              />
            ) : null;
          })}
          <IconButton onClick={() => addContact(rowData)} size="large">
            <PersonAddIcon />
          </IconButton>
        </div>
      ),
    },
    {
      title: t('feature_setting'),
      field: 'featureSetting',
      width: 150,
      render: rowData => {
        return getSettingsArrayFromSite(rowData.featureSetting)
          .map(key => t(`site_setting_${key}`))
          .join(' + ');
      },
    },
    {
      title: t('alert_setting'),
      field: 'alertSetting',
      width: 150,
      render: rowData => {
        return getSettingsArrayFromSite(rowData.alertSetting)
          .map(key => t(`site_setting_${key}`))
          .join(' + ');
      },
    },
    {
      title: '',
      render: rowData => (
        <div className={classes.actionBlock}>
          <IconButton onClick={() => updateSite(rowData)} size="small">
            <EditIcon color="primary" />
          </IconButton>
          {/*<Button variant="contained" color="default" startIcon={<PdfIcon />} onClick={() => doShowGeneratePdfDialog(rowData)} className={classes.pdfButton}>*/}
          {/*    { t('pdf_report') }*/}
          {/*</Button>*/}
        </div>
      ),
    },
  ]);

  const reloadSite = updatedSite => {
    let updatedSites = [...sites];
    const index = sites.findIndex(site => site.siteid === updatedSite.siteid);
    updatedSites.splice(index, 1, updatedSite);
    setSites(updatedSites);
  };

  const fetchSites = async (newUsers, newContacts) => {
    let sites = await getSitesFromDatabase(props.firebase, authUser);
    sites = sites.map(site => {
      const userList = (site.administrators ?? [])
        .map(userId => {
          const user = newUsers.find(user => user.uid === userId);
          return user ? `${user.name} ${user.email}` : '';
        })
        .join(' ');
      const contactList = (site.contacts ?? [])
        .map(contactId => {
          const contact = newContacts.find(user => user.uid === contactId);
          return contact ? `${contact.name} ${contact.email}` : '';
        })
        .join(' ');
      return {
        ...site,
        userList,
        contactList,
      };
    });
    setSites(sites);
  };

  const fetchData = async () => {
    try {
      const organizationSnapshot = await getDocs(props.firebase.organizations());
      const newOrganizations = organizationSnapshot.docs.map(doc => {
        const organizationData = doc.data();
        return {
          uid: doc.id,
          id: doc.id,
          ...organizationData,
        };
      });
      setOrganizations(newOrganizations);

      const userSnapshot = await getDocs(props.firebase.users());
      const newUsers = userSnapshot.docs.map(doc => {
        const user = doc.data();
        return {
          uid: doc.id,
          id: doc.id,
          region: user.region ?? '',
          organization: user.organization ?? '',
          ...user,
        };
      });
      setUsers(newUsers);

      const contactSnapshot = await getDocs(props.firebase.contacts());
      const newContacts = contactSnapshot.docs.map(doc => {
        const contact = doc.data();
        return {
          id: doc.id,
          uid: doc.id,
          ...contact,
        };
      });
      setContacts(newContacts);

      await fetchSites(newUsers, newContacts);
      setError(null);
    } catch (e) {
      setError(`${e}`);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledContainer maxWidth="lg">
      {!loading ? (
        <div>
          {selectedSite ? (
            <AddUserToSite
              firebase={props.firebase}
              reload={fetchData}
              users={users}
              site={selectedSite}
              show={showAddUser}
              toggler={setShowAddUser}
            />
          ) : null}
          {updateUser ? (
            <UpdateUserDialog
              setUser={setUpdateUser}
              user={updateUser}
              firebase={props.firebase}
              organizations={organizations}
              sites={sites}
              show={showUpdateUser}
              toggler={setShowUpdateUser}
            />
          ) : null}
          {selectedSite ? (
            <AddContactToSite
              firebase={props.firebase}
              reload={fetchData}
              contacts={contacts}
              site={selectedSite}
              show={showAddContact}
              toggler={setShowAddContact}
            />
          ) : null}
          {updateContact ? (
            <ContactDialog
              reload={fetchData}
              firebase={props.firebase}
              show={showUpdateContact}
              selectedContact={updateContact}
              sites={sites}
              toggler={setShowUpdateContact}
            />
          ) : null}
          <UpdateSiteDialog
            site={selectedSite}
            show={showUpdateSite}
            toggler={setShowUpdateSite}
            firebase={props.firebase}
            reload={reloadSite}
          />
          {/*<GeneratePdfDialog site={selectedSiteForPdf} show={showGeneratePdfDialog} toggler={setShowGeneratePdfDialog}/>*/}
          <div className={classes.table}>
            <SitesTable columns={columns} data={sites} users={users} />
          </div>
          {error ? (
            <Typography variant="subtitle2" color="error">
              {error}
            </Typography>
          ) : null}

          {userToRemove ? (
            <Dialog open={showRemoveUser} onClose={() => onClose(false)}>
              <DialogContent>
                {t('remove user from site', {
                  user: userToRemove ? userToRemove.email || '' : '',
                  clientName: selectedSite.clientName,
                  site: selectedSite.name,
                })}
              </DialogContent>
              <DialogActions>
                <Button variant="contained" color="secondary" onClick={() => onClose(false)} disabled={loading}>
                  {t('no')}
                </Button>
                <Button variant="contained" color="primary" onClick={() => removeUser()} disabled={loading}>
                  {t('yes')}
                </Button>
              </DialogActions>
            </Dialog>
          ) : null}
          {contactToRemove ? (
            <Dialog open={showRemoveContact} onClose={() => onClose(false)}>
              <DialogContent>
                {t('remove_contact_from_site', {
                  contact: contactToRemove?.email || '',
                  clientName: selectedSite.clientName,
                  site: selectedSite.name,
                })}
              </DialogContent>
              <DialogActions>
                <Button variant="contained" color="secondary" onClick={() => onClose(false)} disabled={loading}>
                  {t('no')}
                </Button>
                <Button variant="contained" color="primary" onClick={() => removeContact()} disabled={loading}>
                  {t('yes')}
                </Button>
              </DialogActions>
            </Dialog>
          ) : null}
        </div>
      ) : (
        <div style={{ textAlign: 'center' }}>
          <CircularProgress />
        </div>
      )}
    </StyledContainer>
  );
};

export default withFirebase(SitesPage);
