export const showToast = (message, severity = 'success') => {
  return {
    type: 'UPDATE_TOAST',
    payload: { message, severity, open: true },
  };
};

export const hideToast = () => {
  return {
    type: 'UPDATE_TOAST',
    payload: { open: false },
  };
};
