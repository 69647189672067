import React from 'react';
import MUIMaterialTable from '@material-table/core';
import { useTranslation } from 'react-i18next';

const MaterialTable = tableProps => {
  const { t } = useTranslation();

  const newTableProps = { ...tableProps };
  newTableProps.options = {
    ...newTableProps.options,
    pageSize: 20,
    pageSizeOptions: [5, 10, 20, 50, 100],
    emptyRowsWhenPaging: false,
  };
  return (
    <MUIMaterialTable
      tableRef={tableProps.tableRef}
      localization={{
        body: {
          emptyDataSourceMessage: t('no records to display'),
        },
        toolbar: {
          searchPlaceholder: t('search'),
        },
        pagination: {
          labelDisplayedRows: t('label display rows'),
          labelRowsPerPage: t('labelRowsPerPage'),
          labelRows: t('rows'),
        },
      }}
      {...newTableProps}
    />
  );
};

export default MaterialTable;
