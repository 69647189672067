import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import SwipeableDrawer from '@mui/material/Drawer';
import Hidden from '@mui/material/Hidden';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MenuIcon from '@mui/icons-material/Menu';
import PeopleIcon from '@mui/icons-material/People';
import ContactIcon from '@mui/icons-material/Contacts';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import DeviceIcon from '@mui/icons-material/Devices';
import ReportIcon from '@mui/icons-material/Subject';
import MessagingIcon from '@mui/icons-material/Message';
import TestIcon from '@mui/icons-material/BugReport';
import PowerIcon from '@mui/icons-material/Power';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import * as ROUTES from '../../constants/routes';
import SignOutButton, { EnhancedSignOutIconButton } from '../SignOut';
import Account from '../Account';
import logo from '../../img/RainBirdLogo.svg';
import FormControl from '@mui/material/FormControl';
import { MenuItem, Select } from '@mui/material';
import { withFirebase } from '../Firebase';
import { useDispatch, useSelector } from 'react-redux';
import { updateUser } from '../../store/actions/userAction';
import { APP_GREEN_COLOR } from '../../constants/const';
import { checkIfPermissionIsGranted } from '../../utils/common';
import NavDropDown from '../Common/NavDropDown';
import CustomListItem from '../Common/CustomListItem';
import { updateDoc } from 'firebase/firestore';

const PREFIX = 'Navigation';

const classes = {
  root: `${PREFIX}-root`,
  menuButton: `${PREFIX}-menuButton`,
  title: `${PREFIX}-title`,
  accountButton: `${PREFIX}-accountButton`,
  floatRight: `${PREFIX}-floatRight`,
  appBar: `${PREFIX}-appBar`,
  logo: `${PREFIX}-logo`,
  drawer: `${PREFIX}-drawer`,
  smMenu: `${PREFIX}-smMenu`,
  languagePicker: `${PREFIX}-languagePicker`,
  languageContainer: `${PREFIX}-languageContainer`,
  pickerIcon: `${PREFIX}-pickerIcon`,
  pickerRoot: `${PREFIX}-pickerRoot`,
};

const Root = styled('div')(() => ({
  [`& .${classes.root}`]: {
    flexGrow: 1,
  },
}));

const StyledDrawer = styled('div')(({ theme }) => ({
  [`& .${classes.drawer}`]: {
    padding: theme.spacing(2),
  },
}));

const StyledNavigationAuth = styled('div')(({ theme }) => ({
  [`& .${classes.menuButton}`]: {
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
    marginLeft: 'auto',
  },
  [`& .${classes.accountButton}`]: {
    marginLeft: '8px',
  },

  [`& .${classes.floatRight}`]: {
    marginLeft: 'auto',
    display: 'flex',
  },

  [`& .${classes.appBar}`]: {
    backgroundColor: APP_GREEN_COLOR,
  },

  [`& .${classes.logo}`]: {
    height: 50,
    width: 'auto',
    marginRight: theme.spacing(5),
  },

  [`& .${classes.smMenu}`]: {
    width: '100%',
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));

const StyledLocalStorageLanguagePicker = styled('div')(({ theme }) => ({
  [`& .${classes.languagePicker}`]: {
    color: 'white',
    '&:before': {
      borderColor: 'white',
    },
    '&:after': {
      borderColor: 'white',
    },
    '&:not(.Mui-disabled):hover::before': {
      borderColor: 'white',
    },
  },

  [`& .${classes.languageContainer}`]: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '5px',
  },

  [`& .${classes.pickerIcon}`]: {
    fill: 'white',
  },

  [`& .${classes.pickerRoot}`]: {
    color: 'white',
  },
}));

const languages = ['ar', 'zh', 'de', 'en', 'es', 'fr', 'it', 'ja', 'ko', 'nl', 'pl', 'pt', 'sv', 'vi'];

const Navigation = () => {
  const authUser = useSelector(state => state.user);

  return (
    <Root className={classes.root}>{authUser ? <NavigationAuth authUser={authUser} /> : <NavigationNonAuth />}</Root>
  );
};

const RespDrawer = ({ menu, drawer, toggleDrawer, toggleModal }) => {
  const { t } = useTranslation();

  return (
    <StyledDrawer>
      <Hidden smUp implementation="css">
        <SwipeableDrawer className={classes.drawer} anchor="right" open={drawer} onClose={() => toggleDrawer(false)}>
          <List>
            {_.map(menu, item => {
              return item.children ? (
                <CustomListItem
                  label={item.label}
                  icon={item.icon}
                  items={item.children}
                  key={'custom-list-item' + item.icon}
                />
              ) : (
                <ListItem key={`${item.label}${item.link}`} button component={Link} to={item.link}>
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText>{item.label}</ListItemText>
                </ListItem>
              );
            })}
          </List>
          <Divider />
          <List>
            <ListItem button onClick={() => toggleModal(true)}>
              <ListItemIcon>
                <AccountCircle />
              </ListItemIcon>
              <ListItemText>{t('my account')}</ListItemText>
            </ListItem>
          </List>
          <EnhancedSignOutIconButton />
          <Divider />
          <div>
            {window.location.pathname === '/' ? <LocalStorageLanguagePicker /> : <DBPersistentLanguagePicker />}
          </div>
        </SwipeableDrawer>
      </Hidden>
    </StyledDrawer>
  );
};

const NavigationAuth = ({ authUser }) => {
  const [showModal, setShowModal] = useState(false);
  const [drawer, setDrawer] = useState(false);

  const [menus, setMenus] = useState({});
  const toggleDrawer = bool => setDrawer(bool);

  useEffect(() => {
    if (!authUser.isTwoFactorAuthorized) {
      return;
    }
    const updatedMenus = {};
    if (authUser.role !== 'distributor') {
      updatedMenus.connect = {
        label: 'Connect',
        icon: <PowerIcon />,
        link: ROUTES.CONNECT,
      };
    }
    updatedMenus.reports = {
      label: 'Reports',
      icon: <ReportIcon />,
      link: ROUTES.REPORTS,
    };
    // Check if the user has the "device" permission
    if (checkIfPermissionIsGranted('device', authUser, ['distributor'])) {
      updatedMenus.devices = {
        label: 'Devices',
        icon: <DeviceIcon />,
        link: ROUTES.DEVICES,
      };
    }

    // Check if the user has the "messaging" permission
    if (checkIfPermissionIsGranted('messaging', authUser)) {
      updatedMenus.messaging = {
        label: 'Messaging',
        icon: <MessagingIcon />,
        link: ROUTES.MESSAGING,
      };
    }

    // Check if the user has the "test" permission
    if (checkIfPermissionIsGranted('test', authUser)) {
      updatedMenus.test = {
        label: 'Test',
        icon: <TestIcon />,
        link: ROUTES.TEST,
      };
    }

    // Check if the user has the "admin" permission
    if (checkIfPermissionIsGranted('admin', authUser)) {
      updatedMenus.admin = {
        label: 'Admin',
        icon: <AccountCircleIcon />,
        children: {
          users: {
            label: 'Users',
            icon: <PeopleIcon />,
            link: ROUTES.USERS,
          },
          contacts: {
            label: 'Contacts',
            icon: <ContactIcon />,
            link: ROUTES.CONTACTS,
          },
          sites: {
            label: 'Sites',
            icon: <LocationOnIcon />,
            link: ROUTES.SITES,
          },
          organizations: {
            label: 'Organizations',
            icon: <AccountBalanceIcon />,
            link: ROUTES.ORGANIZATIONS,
          },
          distributors: {
            label: 'Distributors',
            icon: <PeopleIcon />,
            link: ROUTES.DISTRIBUTORS,
          },
        },
      };
      if (authUser.role === 'super_admin' || authUser.role === 'system_admin') {
        updatedMenus.admin.children.billing = {
          label: 'BillingReports',
          icon: <MonetizationOnIcon />,
          link: ROUTES.MONTHLY_BILLING,
        };
      }
    }

    setMenus(updatedMenus);
  }, [authUser]);

  return (
    <StyledNavigationAuth>
      <AppBar className={classes.appBar} position="static">
        <Toolbar>
          <img className={classes.logo} alt="Rain Bird Logo" src={logo} />
          <div className={classes.smMenu}>
            {_.map(menus, item => {
              return item.children ? (
                <NavDropDown label={item.label} items={item.children} key={item.label + 'dropdown'} />
              ) : (
                <Button key={`${item.label}${item.link}`} color="inherit" component={Link} to={item.link}>
                  {item.label}
                </Button>
              );
            })}

            <div className={classes.floatRight}>
              <DBPersistentLanguagePicker />
              <IconButton
                className={classes.accountButton}
                onClick={() => setShowModal(true)}
                edge="start"
                color="inherit"
                aria-label="menu"
                size="large"
              >
                <AccountCircle />
              </IconButton>
              <SignOutButton />
            </div>
          </div>
          <IconButton className={classes.menuButton} onClick={() => toggleDrawer(true)} color="inherit" size="large">
            <MenuIcon />
          </IconButton>
        </Toolbar>
        <Account show={showModal} toggler={setShowModal} />
      </AppBar>
      <RespDrawer menu={menus} drawer={drawer} toggleDrawer={toggleDrawer} toggleModal={setShowModal} />
    </StyledNavigationAuth>
  );
};
const LocalStorageLanguagePicker = ({ changeLanguage, user }) => {
  const { i18n } = useTranslation();

  const defaultLanguage = i18n.language || 'en';
  const [language, setLanguage] = useState(user && user.interfaceLanguage ? user.interfaceLanguage : defaultLanguage);

  const doChangeLanguage = user => event => {
    const inputLanguage = event.target.value;
    if (changeLanguage) {
      changeLanguage(user, inputLanguage);
    }
    setLanguage(inputLanguage);
    i18n.changeLanguage(inputLanguage);
  };

  return (
    <StyledLocalStorageLanguagePicker className={classes.languageContainer}>
      <FormControl sx={{ m: 1, minWidth: 80 }}>
        <Select
          id="language-picker"
          labelid="language-picker-label"
          className={classes.languagePicker}
          value={language}
          variant="standard"
          label="Langage"
          onChange={doChangeLanguage(user)}
          inputProps={{
            classes: {
              icon: classes.pickerIcon,
              root: classes.pickerRoot,
            },
          }}
          autoWidth
          style={{ color: 'white' }}
        >
          {languages.map(language => (
            <MenuItem value={language} key={language}>
              {language.toUpperCase()}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </StyledLocalStorageLanguagePicker>
  );
};

const NavigationNonAuth = () => {
  return (
    <StyledNavigationAuth>
      <AppBar className={classes.appBar} position="static">
        <Toolbar>
          <img className={classes.logo} alt="Rain Bird Logo" src={logo} />
          <div className={classes.floatRight}>
            <LocalStorageLanguagePicker />
          </div>
        </Toolbar>
      </AppBar>
    </StyledNavigationAuth>
  );
};

const DBPersistentLanguagePicker = withFirebase(({ firebase }) => {
  const authUser = useSelector(state => state.user);
  const dispatch = useDispatch();

  const changeLanguage = async (user, language) => {
    await updateDoc(firebase.user(user.uid), { interfaceLanguage: language });
    dispatch(
      updateUser({
        ...user,
        interfaceLanguage: language,
      }),
    );
  };

  if (window.location.pathname === '/') {
    return <LocalStorageLanguagePicker />;
  }

  return <LocalStorageLanguagePicker changeLanguage={changeLanguage} user={authUser} />;
});

export default Navigation;
