import React, { useEffect, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, CircularProgress, Container, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { getDocs } from 'firebase/firestore';
import { withFirebase } from '../Firebase';
import { format } from 'date-fns';

const ReleaseNotesPage = props => {
  const [releaseNotes, setReleaseNotes] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchReleaseNotes = async () => {
      try {
        const querySnapshot = await getDocs(props.firebase.releaseNotes());
        const notes = querySnapshot.docs.map(doc => doc.data());
        // Sort release notes by date in descending order
        notes.sort((a, b) => new Date(b.date) - new Date(a.date));
        setReleaseNotes(notes);
        setLoading(false);
      } catch (e) {
        console.log(e);
      }
    };
    fetchReleaseNotes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading)
    return (
      <div style={{ textAlign: 'center' }}>
        <CircularProgress />
      </div>
    );

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Release Notes
      </Typography>
      {releaseNotes.map((release, index) => (
        <Accordion key={index} defaultExpanded={index === 0}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <div>
              <Typography variant="h6">{release.title}</Typography>
              <Typography variant="caption" color="textSecondary">
                {format(new Date(release.date), 'MMMM d, yyyy')}
              </Typography>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            {release.sections.map((section, idx) => (
              <div key={idx}>
                <Typography variant="subtitle1">{section.heading}</Typography>
                <ul>
                  {section.details.map((detail, i) => (
                    <li key={i}>{detail}</li>
                  ))}
                </ul>
              </div>
            ))}
          </AccordionDetails>
        </Accordion>
      ))}
    </Container>
  );
};

export default withFirebase(ReleaseNotesPage);
