import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

const RemoveSiteFromDistributor = ({ distributor, site, onClose, removeSite }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const handleRemoveSite = () => {
    setLoading(true);
    removeSite(distributor, site).finally(() => {
      setLoading(false);
      onClose();
    });
  };

  return (
    <Dialog open={true} onClose={onClose}>
      <DialogContent>
        {t('remove site from distributor', {
          clientName: site.clientName,
          site: site.name,
          distributor: distributor.name,
        })}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="secondary" onClick={onClose} disabled={loading}>
          {t('no')}
        </Button>
        <Button variant="contained" color="primary" onClick={handleRemoveSite} disabled={loading}>
          {t('yes')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default RemoveSiteFromDistributor;
