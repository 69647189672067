import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';

import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import { withFirebase } from '../Firebase';
import TableIcons from '../../constants/tableIcons';
import ContactDialog from './dialogs/ContactModal';
import { useTranslation } from 'react-i18next';
import MaterialTable from '../MaterialTable/MaterialTable';
import { getSitesFromDatabase, REGION_LOOKUP } from '../../utils/common';
import { useSelector } from 'react-redux';
import { getDocs } from 'firebase/firestore';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import AdornedButton from '../Button/AdornedButton';
import axios from 'axios';
import * as API from '../../constants/api';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { resources } from '../../i18n';
import { capitalize } from 'lodash';

const PREFIX = 'index';

const classes = {
  link: `${PREFIX}-link`,
  table: `${PREFIX}-table`,
  select: `${PREFIX}-select`,
};

const StyledContainer = styled(Container)(({ theme }) => ({
  [`& .${classes.link}`]: {
    cursor: 'pointer',
    fontWeight: 'bold',
  },

  [`& .${classes.table}`]: {
    margin: theme.spacing(2, 0),
  },

  [`& .${classes.select}`]: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
  },
}));

const ContactTable = ({ data, columns }) => {
  const { t } = useTranslation();

  return (
    <MaterialTable
      icons={TableIcons}
      title={t('contacts')}
      columns={columns}
      data={data}
      options={{
        filtering: true,
      }}
    />
  );
};

const ContactsPage = props => {
  const { t } = useTranslation();
  const [contacts, setContacts] = useState([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const authUser = useSelector(state => state.user);
  const [sites, setSites] = useState([]);
  const [selectedContact, setSelectedContact] = useState({});
  const [showRemoveMessage, setShowRemoveContact] = useState(false);
  const [showSaveMessage, setShowContactDialog] = useState(false);
  const [columns] = useState([
    {
      title: t('email'),
      field: 'email',
      render: rowData => (
        <Link className={classes.link} onClick={() => openUpdateContactModal(rowData)}>
          {rowData.email || ''}
        </Link>
      ),
    },
    {
      title: t('contact name'),
      field: 'name',
    },
    {
      title: t('language'),
      field: 'language',
      render: rowData => {
        return capitalize(t(resources[rowData.language]?.name ?? ''));
      },
    },
    {
      title: t('region'),
      field: 'region',
      lookup: REGION_LOOKUP,
      render: rowData => rowData.region?.toUpperCase(),
    },
    {
      title: '',
      field: 'actions',
      render: rowData => (
        <div>
          <IconButton onClick={() => openUpdateContactModal(rowData)} size="small">
            <EditIcon color="primary" />
          </IconButton>
          <IconButton onClick={() => deleteContactClicked(rowData)} size="small">
            <HighlightOffIcon color="error" />
          </IconButton>
        </div>
      ),
    },
  ]);

  const openUpdateContactModal = contact => {
    setSelectedContact(contact);
    setShowContactDialog(true);
  };

  const showAddDialog = () => {
    setSelectedContact(null);
    setShowContactDialog(true);
  };

  const closeRemoveContact = () => {
    setSelectedContact(null);
    setShowRemoveContact(false);
  };
  const getContacts = async () => {
    const contactSnapshot = await getDocs(props.firebase.contacts());
    const newContacts = contactSnapshot.docs.map(doc => {
      return {
        ...doc.data(),
        id: doc.id,
      };
    });
    setContacts(newContacts);
  };

  const deleteContactClicked = contact => {
    setSelectedContact(contact);
    setShowRemoveContact(true);
  };

  const removeContact = async () => {
    try {
      setDeleteLoading(true);
      setShowRemoveContact(false);
      await axios.post(API.DELETE_CONTACT, {
        id: selectedContact.id,
      });
      setContacts(contacts.filter(contact => contact.id !== selectedContact.id));
    } catch (e) {
      setError(`Error occurred while deleting contact. Please try again.`);
    } finally {
      setDeleteLoading(false);
      setSelectedContact(null);
    }
  };

  async function fetchData() {
    try {
      await getContacts();
      setSites(await getSitesFromDatabase(props.firebase, authUser));
      setError(null);
      setLoading(false);
    } catch (e) {
      setError(`${e}`);
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <StyledContainer maxWidth="md">
      {!loading ? (
        <div>
          <Button variant="contained" color="primary" onClick={() => showAddDialog()}>
            {t('new_contact')}
          </Button>
          <ContactDialog
            reload={fetchData}
            firebase={props.firebase}
            show={showSaveMessage}
            selectedContact={selectedContact}
            sites={sites}
            toggler={setShowContactDialog}
          />
          <div className={classes.table}>
            <ContactTable columns={columns} data={contacts} />
          </div>
          {selectedContact ? (
            <Dialog open={showRemoveMessage} onClose={() => closeRemoveContact()}>
              <DialogContent>{t('delete_contact_confirm')}</DialogContent>
              <DialogActions>
                <AdornedButton
                  variant="contained"
                  color="secondary"
                  onClick={() => closeRemoveContact()}
                  disabled={loading || deleteLoading}
                >
                  {t('no')}
                </AdornedButton>
                <AdornedButton
                  variant="contained"
                  color="primary"
                  onClick={() => removeContact()}
                  disabled={loading || deleteLoading}
                  loading={deleteLoading}
                >
                  {t('yes')}
                </AdornedButton>
              </DialogActions>
            </Dialog>
          ) : null}
          {error ? (
            <Typography variant="subtitle2" color="error">
              {error}
            </Typography>
          ) : null}
        </div>
      ) : (
        <div style={{ textAlign: 'center' }}>
          <CircularProgress />
        </div>
      )}
    </StyledContainer>
  );
};

export default withFirebase(ContactsPage);
